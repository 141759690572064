var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jwtDecode } from "jwt-decode";
// Ref: https://marmelab.com/react-admin/AuthProviderWriting.html
var ApiBaseUrl = import.meta.env.VITE_API_BASE_URL;
var AuthKey = 'auth';
var AccessTokenKey = 'accessToken';
var IsLogoutOnPermissionError = false;
var login = function (params) {
    if (!params.username || !params.password) {
        return Promise.reject();
    }
    var request = new Request("".concat(ApiBaseUrl, "/auth/login"), {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var statusCode, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    statusCode = response.status;
                    if (!(statusCode < 200 || statusCode >= 300)) return [3 /*break*/, 2];
                    _a = Error.bind;
                    return [4 /*yield*/, response.json()];
                case 1: throw new (_a.apply(Error, [void 0, (_b.sent()).message]))();
                case 2: return [4 /*yield*/, response.json()];
                case 3: return [2 /*return*/, _b.sent()];
            }
        });
    }); })
        .then(function (auth) {
        localStorage.setItem(AccessTokenKey, JSON.stringify(auth.accessToken));
        var decodedToken = jwtDecode(auth.accessToken);
        var obj = {
            id: decodedToken.sub,
            email: decodedToken.email,
            username: decodedToken.username
        };
        localStorage.setItem(AuthKey, JSON.stringify(obj));
        // Fetch and store user ACL after login
    })
        .catch(function (error) {
        // TODO: Add more info
        console.error(error);
        throw new Error(error.message);
    });
};
// login with 2fa
var loginWithTwofaChecking = function (params) {
    if (!params.username || !params.password) {
        return Promise.reject();
    }
    var request = new Request("".concat(ApiBaseUrl, "/auth/login"), {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var statusCode, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    statusCode = response.status;
                    if (!(statusCode < 200 || statusCode >= 300)) return [3 /*break*/, 2];
                    _a = Error.bind;
                    return [4 /*yield*/, response.json()];
                case 1: throw new (_a.apply(Error, [void 0, (_b.sent()).message]))();
                case 2: return [4 /*yield*/, response.json()];
                case 3: 
                // return await { data: response.json() }
                return [2 /*return*/, _b.sent()];
            }
        });
    }); })
        // .then(auth => {
        //     console.log(auth, '==auth')
        //     //     localStorage.setItem(AccessTokenKey, JSON.stringify(auth.accessToken))
        //     //     const decodedToken: any = jwtDecode(auth.accessToken)
        //     //     const obj = {
        //     //         id: decodedToken.sub,
        //     //         email: decodedToken.email,
        //     //         username: decodedToken.username
        //     //     }
        //     //     localStorage.setItem(AuthKey, JSON.stringify(obj))
        // })
        .catch(function (error) {
        // TODO: Add more info
        console.error(error);
        throw new Error(error.message);
    });
};
// login with 2fa verficiation
var twofaTokenChecking = function (params) {
    if (!params.username) {
        return Promise.reject();
    }
    var request = new Request("".concat(ApiBaseUrl, "/2fa/validate"), {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var statusCode, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    statusCode = response.status;
                    if (!(statusCode < 200 || statusCode >= 300)) return [3 /*break*/, 2];
                    _a = Error.bind;
                    return [4 /*yield*/, response.json()];
                case 1: throw new (_a.apply(Error, [void 0, (_b.sent()).message]))();
                case 2: return [4 /*yield*/, response.json()];
                case 3: 
                // return await { data: response.json() }
                return [2 /*return*/, _b.sent()];
            }
        });
    }); })
        .catch(function (error) {
        // TODO: Add more info
        console.log(error, '==json err msg', error.message);
        console.error(error);
        throw new Error(error.message);
    });
};
// register
var register = function (params) {
    var request = new Request("".concat(ApiBaseUrl, "/auth/register"), {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var statusCode, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    statusCode = response.status;
                    if (!(statusCode < 200 || statusCode >= 300)) return [3 /*break*/, 2];
                    _a = Error.bind;
                    return [4 /*yield*/, response.json()];
                case 1: throw new (_a.apply(Error, [void 0, (_b.sent()).message]))();
                case 2: return [4 /*yield*/, response];
                case 3: 
                // return await { data: response.json() }
                return [2 /*return*/, _b.sent()];
            }
        });
    }); })
        .catch(function (error) {
        // TODO: Add more info
        // if (error == "Error: email must be an email"){
        // notify(error)
        // }
        // useNotify("456")
        console.error(error, '==err in register', error == "Error: email must be an email");
        throw new Error(error);
    });
};
// register success- email with token
var registerSuccess = function (params) {
    var request = new Request("".concat(ApiBaseUrl, "/auth/register/success"), {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var statusCode, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    statusCode = response.status;
                    if (!(statusCode < 200 || statusCode >= 300)) return [3 /*break*/, 2];
                    _a = Error.bind;
                    return [4 /*yield*/, response.json()];
                case 1: throw new (_a.apply(Error, [void 0, (_b.sent()).message]))();
                case 2: return [4 /*yield*/, response.json()];
                case 3: 
                // return await { data: response.json() }
                return [2 /*return*/, _b.sent()];
            }
        });
    }); })
        .catch(function (error) {
        // TODO: Add more info
        console.error(error, '==err');
        throw new Error(error.message);
    });
};
// login success after 2fa
var loginSuccess = function (auth) {
    // localStorage.setItem("accessToken", JSON.stringify(auth.accessToken))
    localStorage.setItem(AccessTokenKey, auth.accessToken);
    var decodedToken = jwtDecode(auth.accessToken);
    var obj = {
        id: decodedToken.sub,
        email: decodedToken.email,
        username: decodedToken.username,
        // language: decodedToken.language ?? "en",
    };
    console.log(auth.accessToken, '==login success');
    localStorage.setItem(AuthKey, JSON.stringify(obj));
};
// send email
var forgetPW = function (params) {
    if (!params.email) {
        return Promise.reject();
    }
    var request = new Request("".concat(ApiBaseUrl, "/auth/forgetPW"), {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var statusCode, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    statusCode = response.status;
                    if (!(statusCode < 200 || statusCode >= 300)) return [3 /*break*/, 2];
                    _a = Error.bind;
                    return [4 /*yield*/, response.json()];
                case 1: throw new (_a.apply(Error, [void 0, (_b.sent()).message]))();
                case 2: return [4 /*yield*/, response.json()];
                case 3: return [2 /*return*/, _b.sent()];
            }
        });
    }); })
        .catch(function (error) {
        // TODO: Add more info
        console.error(error);
        throw new Error(error.message);
    });
};
// reset success
var resetSuccess = function (params) {
    var request = new Request("".concat(ApiBaseUrl, "/auth/setNewPW/success"), {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var statusCode, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    statusCode = response.status;
                    if (!(statusCode < 200 || statusCode >= 300)) return [3 /*break*/, 2];
                    _a = Error.bind;
                    return [4 /*yield*/, response.json()];
                case 1: throw new (_a.apply(Error, [void 0, (_b.sent()).message]))();
                case 2: return [4 /*yield*/, response.json()];
                case 3: 
                // return await { data: response.json() }
                return [2 /*return*/, _b.sent()];
            }
        });
    }); })
        .catch(function (error) {
        // TODO: Add more info
        console.error(error, '==err');
        throw new Error(error.message);
    });
};
var logout = function (params) {
    willLogout();
    return Promise.resolve();
};
var checkAuth = function (params) {
    return localStorage.getItem(AuthKey) ? Promise.resolve() : Promise.reject();
};
var checkError = function (error) {
    var status = error.status;
    var cachedToken = localStorage.getItem('accessToken');
    // if (cachedToken) {
    //     return Promise.resolve({ redirectTo: '/admin/subjects', logoutUser: false })
    // }
    switch (status) {
        case 401:
        case 403:
            // Unauthorized and Forbidden
            if (IsLogoutOnPermissionError) {
                // Logout
                willLogout();
                // localStorage.clear();
                // sessionStorage.clear();
                return Promise.reject();
            }
            else {
                // Redirect without logout user
                // localStorage.clear();
                // sessionStorage.clear();
                return Promise.reject({ redirectTo: '/admin/unauthorized', logoutUser: false });
            }
    }
    return Promise.resolve();
};
var getIdentity = function () {
    try {
        var _a = JSON.parse(localStorage.getItem(AuthKey)), id = _a.id, username = _a.username, avatar = _a.avatar;
        return Promise.resolve({ id: id, fullName: username, avatar: avatar });
    }
    catch (error) {
        return Promise.reject(error);
    }
};
var getPermissions = function (params) {
    return Promise.resolve();
};
var willLogout = function () {
    localStorage.removeItem(AuthKey);
    localStorage.removeItem(AccessTokenKey);
    localStorage.removeItem('userACL'); // Remove user ACL on logout
};
var authProvider = {
    login: login,
    logout: logout,
    checkAuth: checkAuth,
    checkError: checkError,
    getIdentity: getIdentity,
    getPermissions: getPermissions
};
export { authProvider, forgetPW, resetSuccess, loginWithTwofaChecking, twofaTokenChecking, loginSuccess, register, registerSuccess, };

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate, } from 'react-admin';
import { CustomLocalesMenu } from './helper/CustomLocale';
import { Button } from '@mui/material';
export var AppBarToolbar = function (_a) {
    var orgName = _a.orgName;
    var translate = useTranslate();
    return (_jsxs(_Fragment, { children: [_jsx(CustomLocalesMenu, {}), _jsx(Button, { disabled: true, variant: "outlined", style: {
                    color: "white", borderColor: "white",
                    margin: "0 1rem", textAlign: "center",
                    textTransform: 'capitalize'
                }, children: "".concat(translate("pages.userList.fields.organization"), " : ").concat(orgName !== null && orgName !== void 0 ? orgName : "N/A") })] }));
};

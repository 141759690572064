// @ts-nocheck
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var dbDateHelperMMDD = function (dateStr) {
    var date = new Date(dateStr);
    var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    var day = String(date.getDate()).padStart(2, '0');
    return "".concat(day, "/").concat(month);
};
var dbDateHelper = function (dateStr) {
    var date = new Date(dateStr);
    var formattedDate = date.toLocaleDateString();
    return formattedDate;
};
var dbDateHelperWithHHMM = function (dateStr) {
    var date = new Date(dateStr);
    var formattedTime = date.toLocaleDateString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Set to true for 12-hour format
    });
    return formattedTime;
};
var splitDateTimeHelper = function (dateStr) {
    //original dateStr: "7/16/2024, 4:55:23 PM"
    var dateParts = dateStr.split(",");
    var formattedDate = dateParts[0];
    return formattedDate;
};
var blueIDHelper = function (idStr) {
    if (idStr) {
        var asterisks = "*".repeat(Math.min(4, idStr.length));
        var modifiedString = asterisks + idStr.substring(4);
        return modifiedString;
    }
    return idStr;
};
var subjectIdHelper = function (idStr) {
    if (idStr) {
        var modifiedString = "...." + idStr.slice(-4);
        return modifiedString;
    }
    return idStr;
};
var pdfSubjectIdHelper = function (idStr) {
    if (idStr) {
        var modifiedString = idStr.slice(-4);
        return modifiedString;
    }
    return idStr;
};
var longNameHelper = function (longStr) {
    if (longStr && (longStr === null || longStr === void 0 ? void 0 : longStr.length) > 26) {
        var modifiedString = "...." + longStr.slice(-4);
        return modifiedString;
    }
    return longStr;
};
var imgSrcPathHelper = function (src) {
    if (src) {
        var modifiedString = src.replace(/\\/g, "/");
        console.log('==log modifiedString', modifiedString);
        return modifiedString;
    }
    return src;
};
var dragImgNameHelper = function (str) {
    if (str && (str === null || str === void 0 ? void 0 : str.length) > 24) {
        return "..." + str.slice(-21);
    }
    return str;
    // return "dragImgNameHelperdragImgNameHelperdragImgNameHelper".slice(-21)
};
var emptyFunc = function () {
};
var secToMin = function (secondsStr) {
    var minutes = Math.floor(secondsStr / 60);
    var seconds = secondsStr % 60;
    var timeString = "".concat(minutes, " min \n    ").concat(seconds > 0 ? seconds + " sec" : "", " ");
    return timeString;
};
//flatten nested obj with _ (e.g. image: {x: 1} -> image_x: 1)
var flattenObjectWithUnderscores = function (obj, parentKey) {
    if (parentKey === void 0) { parentKey = ''; }
    var result = {};
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            var newKey = parentKey ? "".concat(parentKey, "_").concat(key) : key;
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                Object.assign(result, flattenObjectWithUnderscores(obj[key], newKey));
            }
            else {
                result[newKey] = obj[key];
            }
        }
    }
    console.log(result, '==result');
    return result;
};
//exclude (delete) array of key from object
var excludeFromObj = function (obj, excludeArr) {
    var newObj = __assign({}, obj);
    excludeArr.forEach(function (key) {
        if (newObj.hasOwnProperty(key)) {
            delete newObj[key];
        }
    });
    return newObj;
};
// update exam panel's dates (no repeat, newest to oldest)
var updateNewExamDates = function (examDates) {
    var prevExamDates = __spreadArray([], examDates, true);
    // let prevExamDates = [
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "ce69a0ed-2f1d-4c01-8ebe-001f1f42e97c",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Completed",
    //         "eye": "Both",
    //         "ai_model": "FP",
    //         "risk_of_ad": "Low",
    //         "confidence_level": 90,
    //         "exam_date": "2024-11-04T03:52:20.000Z",
    //         "analysis_date": "2024-11-07T09:39:45.000Z",
    //         "analysisDataPdf": "/uploads/1723451663694-449397384-SM24-08-AISP_FP_Report_Example-V0.1.pdf",
    //         "created_at": "2024-11-04T03:52:22.371Z",
    //         "updated_at": "2024-11-07T09:39:45.000Z",
    //         "deleted_at": null
    //     },
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "a3983d68-9a74-407f-a118-3bb2f627c339",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Completed",
    //         "eye": "Both",
    //         "ai_model": "FP",
    //         "risk_of_ad": null,
    //         "confidence_level": null,
    //         "exam_date": "2024-10-28T08:09:36.000Z",
    //         "analysis_date": null,
    //         "analysisDataPdf": null,
    //         "created_at": "2024-10-28T08:09:37.268Z",
    //         "updated_at": "2024-11-04T01:53:22.000Z",
    //         "deleted_at": null
    //     },
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "b37d29db-6f2f-4534-981a-5b259819a11a",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Pending",
    //         "eye": null,
    //         "ai_model": "FP",
    //         "risk_of_ad": null,
    //         "confidence_level": null,
    //         "exam_date": "2024-10-24T09:09:07.000Z",
    //         "analysis_date": null,
    //         "analysisDataPdf": null,
    //         "created_at": "2024-10-24T09:09:01.270Z",
    //         "updated_at": "2024-10-24T09:14:06.000Z",
    //         "deleted_at": null
    //     },
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "ce2fadd5-98e6-4892-9bab-8d8c37fd6ea0",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Pending",
    //         "eye": null,
    //         "ai_model": null,
    //         "risk_of_ad": null,
    //         "confidence_level": null,
    //         "exam_date": "2024-10-24T09:25:40.000Z",
    //         "analysis_date": null,
    //         "analysisDataPdf": null,
    //         "created_at": "2024-10-24T09:25:34.331Z",
    //         "updated_at": "2024-10-24T09:25:34.000Z",
    //         "deleted_at": null
    //     },
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "a883db75-d6d5-4684-87dc-8dae9c5c612d",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Completed",
    //         "eye": null,
    //         "ai_model": "FP",
    //         "risk_of_ad": null,
    //         "confidence_level": null,
    //         "exam_date": "2024-11-05T01:13:36.000Z",
    //         "analysis_date": null,
    //         "analysisDataPdf": null,
    //         "created_at": "2024-11-05T01:13:37.158Z",
    //         "updated_at": "2024-11-05T01:18:38.000Z",
    //         "deleted_at": null
    //     },
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "4d946597-2527-4246-9e39-8e901a0695a5",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Pending",
    //         "eye": "Both",
    //         "ai_model": "FP",
    //         "risk_of_ad": null,
    //         "confidence_level": null,
    //         "exam_date": "2024-11-05T03:36:59.000Z",
    //         "analysis_date": null,
    //         "analysisDataPdf": null,
    //         "created_at": "2024-11-05T03:37:00.477Z",
    //         "updated_at": "2024-11-05T03:37:25.000Z",
    //         "deleted_at": null
    //     },
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "6bd873ea-cb28-4f53-aa86-9111ae1b3996",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Completed",
    //         "eye": "Both",
    //         "ai_model": "FP",
    //         "risk_of_ad": null,
    //         "confidence_level": null,
    //         "exam_date": "2024-11-04T03:49:32.000Z",
    //         "analysis_date": null,
    //         "analysisDataPdf": null,
    //         "created_at": "2024-11-04T03:49:34.284Z",
    //         "updated_at": "2024-11-04T03:50:42.000Z",
    //         "deleted_at": null
    //     },
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "815e5866-7059-4aa9-b95d-9f298b28bcea",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Completed",
    //         "eye": "Both",
    //         "ai_model": "FP",
    //         "risk_of_ad": null,
    //         "confidence_level": null,
    //         "exam_date": "2024-10-29T07:50:37.000Z",
    //         "analysis_date": null,
    //         "analysisDataPdf": null,
    //         "created_at": "2024-10-29T07:50:39.141Z",
    //         "updated_at": "2024-10-29T07:53:15.000Z",
    //         "deleted_at": null
    //     },
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "93fc24da-a084-46c2-9e6c-a3e5959d34c8",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Pending",
    //         "eye": "Both",
    //         "ai_model": "FP",
    //         "risk_of_ad": null,
    //         "confidence_level": null,
    //         "exam_date": "2024-11-07T00:00:00.000Z",
    //         "analysis_date": null,
    //         "analysisDataPdf": null,
    //         "created_at": "2024-11-05T03:37:53.495Z",
    //         "updated_at": "2024-11-05T06:16:06.000Z",
    //         "deleted_at": null
    //     },
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "43e22862-dc6a-4945-8cf6-dd55b1cf7ef5",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Completed",
    //         "eye": "Both",
    //         "ai_model": "FP",
    //         "risk_of_ad": null,
    //         "confidence_level": null,
    //         "exam_date": "2024-11-05T03:34:32.000Z",
    //         "analysis_date": null,
    //         "analysisDataPdf": null,
    //         "created_at": "2024-11-05T03:34:33.223Z",
    //         "updated_at": "2024-11-05T03:42:32.000Z",
    //         "deleted_at": null
    //     },
    //     {
    //         "subject_customId": "2509909a",
    //         "id": "f38ba1f0-6afa-4d7d-a594-f8a529aa5166",
    //         "subject_id": "bcdf116d-a0d6-4eb5-a9fe-02a4cb4a1703",
    //         "status": "Completed",
    //         "eye": "Both",
    //         "ai_model": "FP",
    //         "risk_of_ad": null,
    //         "confidence_level": 80,
    //         "exam_date": "2024-11-04T04:03:52.000Z",
    //         "analysis_date": null,
    //         "analysisDataPdf": null,
    //         "created_at": "2024-11-04T04:03:54.117Z",
    //         "updated_at": "2024-11-07T09:07:10.050Z",
    //         "deleted_at": null
    //     }
    // ];
    var newPrevExamDates = new Set(prevExamDates.map(function (item) { return item.exam_date.split('T')[0]; }));
    var newUniqExamDatesArray = Array.from(newPrevExamDates).map(function (date) { return ({ exam_date: date }); });
    prevExamDates = newUniqExamDatesArray.sort(function (a, b) { return new Date(b.exam_date) - new Date(a.exam_date); });
    return prevExamDates;
};
export { dbDateHelper, dbDateHelperWithHHMM, blueIDHelper, imgSrcPathHelper, splitDateTimeHelper, subjectIdHelper, emptyFunc, secToMin, dragImgNameHelper, flattenObjectWithUnderscores, excludeFromObj, longNameHelper, pdfSubjectIdHelper, dbDateHelperMMDD, updateNewExamDates, };

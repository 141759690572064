var Gender;
(function (Gender) {
    Gender["Male"] = "male";
    Gender["Female"] = "female";
})(Gender || (Gender = {}));
var EducationLevel;
(function (EducationLevel) {
    EducationLevel[EducationLevel["Lv1"] = 1] = "Lv1";
    EducationLevel[EducationLevel["Lv2"] = 2] = "Lv2";
    EducationLevel[EducationLevel["Lv3"] = 3] = "Lv3";
    EducationLevel[EducationLevel["Lv4"] = 4] = "Lv4";
    EducationLevel[EducationLevel["Lv5"] = 5] = "Lv5"; // > 12 
})(EducationLevel || (EducationLevel = {}));
var GenderChoices = [
    { id: Gender.Male, name: 'M' },
    { id: Gender.Female, name: 'F' }
];
var EducationLevelChoices = [
    { id: EducationLevel.Lv1, name: '0-3 ' },
    { id: EducationLevel.Lv2, name: '4-6 ' },
    { id: EducationLevel.Lv3, name: '7-9 ' },
    { id: EducationLevel.Lv4, name: '10-12 ' },
    { id: EducationLevel.Lv5, name: '> 12 ' },
];
//  MUI 
var GenderMuiChoices = [
    { value: 'M', label: 'M' },
    { value: 'F', label: 'F' },
    { value: 'Prefer not to disclose', label: 'Prefer not to disclose' },
];
var EducationLevelMuiChoices = [
    { value: 'Unknown', label: 'Unknown' },
    { value: '0-3 yrs', label: '0-3 yrs' },
    { value: '4-6 yrs', label: '4-6 yrs' },
    { value: '7-9 yrs', label: '7-9 yrs' },
    { value: '10-12 yrs', label: '10-12 yrs' },
    { value: '> 12 yrs', label: '> 12 yrs' },
];
export { Gender, GenderChoices, GenderMuiChoices, EducationLevelChoices, EducationLevelMuiChoices, };

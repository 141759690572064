// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react';
import {
    BooleanInput,
    DateField,
    Edit,
    Form,
    Labeled,
    PrevNextButtons,
    ReferenceField,
    SelectInput,
    TextField,
    Toolbar,
    useRecordContext,
    useTranslate,
    DateInput, minValue,
    DateInput as RADateInput,
    minValue as RAminValue,
    maxValue as RAmaxValue
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Box, Grid, Typography, Link, Button, CircularProgress, Slider } from '@mui/material';
import { filter as raFilter } from '../utils/reactAdminComponentWizard'
import { filter } from '../utils/componentWizard';
import SearchIcon from '@mui/icons-material/Search';
import SubjectList from '../subjectPage/SubjectList';
import { number } from 'prop-types';
import SubjectsDatePicker from '../utils/dateInputFilter';

const numberRanges = {
    userNum : [
        { value: '0-10', label: '0-10' }, 
        { value: '11-20', label:'11-20' }, 
        { value: '21-30', label: '21-30' }, 
        { value: '31-40', label: '31-40' }, 
        { value: '41-50', label: '41-50' }, 
        { value: '>50', label: '>50' }
    ]
}

const numberRanges2 = {
    groupNum : [
        { value: '0-10', label: '0-10' }, 
        { value: '11-20', label:'11-20' }, 
        { value: '21-30', label: '21-30' }, 
        { value: '31-40', label: '31-40' }, 
        { value: '41-50', label: '41-50' }, 
        { value: '>50', label: '>50' }
    ]
}

interface FilterListProps {
    search: any;
    isLoading: boolean;
    filters: any;
    setFilters: (filters: any) => void;
    params: any;
}

const Spacer = () => <Box mb={1}>&nbsp;</Box>;


const FilterList: React.FC<FilterListProps> = ({ search, isLoading, filters, setFilters, params, translate }) => {

    let sessonStorageJSON = JSON.parse(sessionStorage.getItem(`${window.location.pathname}_filter`)) ?? {}
    let updatedFilters = { ...filters, ...sessonStorageJSON }
    

    return (

        <Grid container spacing={1} justifyContent="start" >


            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <filter.textField filters={filters} setFilters={setFilters} label={translate(`pages.organizations.fields.id`)} name="id" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <filter.dropDownList filters={filters} setFilters={setFilters} label={translate(`pages.userList.fields.organization`)} name="organizations" options={params} />
                
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <filter.dropDownList filters={filters} setFilters={setFilters} label={translate(`pages.organizations.fields.groupNum`)} name="groupNum" options={numberRanges2}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <filter.dropDownList filters={filters} setFilters={setFilters} label={translate(`pages.organizations.fields.userNum`)} name="userNum" options={numberRanges}/>
               
            </Grid>
            
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <SubjectsDatePicker filters={updatedFilters} setFilters={setFilters} label={translate(`pages.organizations.fields.createdAt`)} name="createdAt" 
                    minDate={new Date(2019, 8, 1)} maxDate={new Date()} notNeedRequired={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <SubjectsDatePicker filters={updatedFilters} setFilters={setFilters} label={translate(`pages.organizations.fields.updatedAt`)} name="updatedAt" 
                    minDate={new Date(2019, 8, 1)} maxDate={new Date()} notNeedRequired={true} />
            </Grid>

            {/*  <Grid item alignItems="end" justifyContent="end" >
                <Button variant="contained" disabled={isLoading} onClick={() => { search() }} color="common" startIcon={
                    <SearchIcon
                        sx={{
                            animation: 'pulse 2s infinite ease-in-out',
                            '@keyframes pulse': {
                                '0%': {
                                    transform: 'scale(1)',
                                },
                                '50%': {
                                    transform: 'scale(1.5)',
                                },
                                '100%': {
                                    transform: 'scale(1)',
                                },
                            },
                        }}
                    />
                }
                    sx={{
                        marginTop: "0.5em",
                        height: "3.5em",
                        width: "9em",
                        fontWeight: "900",
                        color: "#0d47a1",
                        borderRadius: '20px',
                        boxShadow: '0px 6px 8px gray',
                        opacity: "0.6",
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                            backgroundColor: '#1976d2',
                            color: 'white',
                            transform: 'scale(1.05)',
                            boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.3)',
                            opacity: "1",
                        },
                        '&:active': {
                            transform: 'scale(0.95)',
                        },
                        '&:hover .MuiSvgIcon-root': {
                            animation: 'pulseHover 1.5s infinite ease-in-out',
                        },
                        '@keyframes pulseHover': {
                            '0%': {
                                transform: 'scale(1)',
                            },
                            '50%': {
                                transform: 'scale(1.8)',
                            },
                            '100%': {
                                transform: 'scale(1)',
                            },
                        },
                    }}>
                    {isLoading ? <CircularProgress size={24} /> : 'Search'}
                </Button>
            </Grid>  */}
        </Grid>
    )
};





export default FilterList;

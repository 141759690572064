import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useTranslate, useListContext, } from 'react-admin';
var MobileGrid = function () {
    var translate = useTranslate();
    var _a = useListContext(), data = _a.data, isLoading = _a.isLoading;
    var listContext = useListContext();
    console.log('==log data from sub mobile grid', listContext);
    return (_jsx(Box, { margin: "0.5em" }));
};
export default MobileGrid;

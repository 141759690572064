var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslate, } from "react-admin";
import { Typography, Grid, TextField, IconButton, } from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import DeleteIcon from "@mui/icons-material/Delete";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import { imgSrcPathHelper, dragImgNameHelper } from "../../utils/helper";
import { Draggable } from "./dragAndDrop/Draggable";
var styles = {
    documentBlock: {
        width: 160,
        height: 200,
        margin: "20px auto",
        marginTop: 8,
        overflow: "hidden",
    },
    controls: {
        maxWidth: 160,
        margin: "0 auto",
        marginTop: 8,
    },
    container: {
        maxHeight: "10rem",
        textAlign: "start",
    },
    gradableTitle: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        backgroundColor: "#e0eefb",
        // backgroundColor: "#e0eefb"
    },
    expandIconStyle: {
        position: "absolute",
        right: 0,
        top: 0,
        color: "white",
    },
    commentTitle: {
        color: "#adadda",
    },
    commentBlock: {
        borderRadius: "5px",
        boxShadow: "0 0 2px 0.1px gray",
    },
    delButton: {
        color: "#1561a5",
    },
};
var GradableImageGrid = function (_a) {
    var image = _a.image, comment = _a.comment, eyeType = _a.eyeType, handleOpenImage = _a.handleOpenImage, handelImageGrid = _a.handelImageGrid, idx = _a.idx, id = _a.id, isDragging = _a.isDragging, imgName = _a.imgName;
    var translate = useTranslate();
    return (_jsxs(_Fragment, { children: [isDragging && (_jsx(Draggable, { id: id, children: _jsxs(Grid, { container: true, xs: 12, padding: 1, margin: 1, style: styles.container, children: [_jsxs(Grid, { container: true, xs: 4, children: [_jsxs(Grid, { container: true, xs: 12, display: "flex", justifyContent: "end", children: [_jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () {
                                                handelImageGrid(idx, "delete");
                                            }, style: styles.delButton, children: _jsx(DeleteIcon, { fontSize: "small" }) }), _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(ControlCameraIcon, { fontSize: "small" }) })] }), _jsx(Grid, { xs: 12, display: "flex", flexWrap: "wrap", position: "relative", children: _jsx("img", { src: imgSrcPathHelper(image), alt: "Gradable image", width: "80", height: "80" }) })] }), _jsxs(Grid, { container: true, xs: 8, paddingX: 1, children: [_jsx(Typography, { className: "small-text normal-bold", style: styles.commentTitle, children: translate("fp.comment") }), _jsx(TextField, { id: "outlined-basic", multiline: true, rows: 3, label: "", variant: "outlined", value: comment !== null && comment !== void 0 ? comment : "", onChange: function (e) {
                                        handelImageGrid(idx, "cmt", e.target.value);
                                    }, sx: styles.commentBlock }), _jsx(Grid, { className: "small-text", xs: 12, children: dragImgNameHelper(imgName) })] })] }) })), !isDragging && (_jsxs(Grid, { container: true, xs: 12, padding: 1, margin: 1, style: styles.container, children: [_jsxs(Grid, { container: true, xs: 4, children: [_jsxs(Grid, { container: true, xs: 12, display: "flex", justifyContent: "end", children: [_jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () {
                                            handelImageGrid(idx, "delete");
                                        }, style: styles.delButton, children: _jsx(DeleteIcon, { fontSize: "small" }) }), _jsx(Draggable, { id: id, children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(ControlCameraIcon, { fontSize: "small" }) }) })] }), _jsxs(Grid, { xs: 12, display: "flex", flexWrap: "wrap", position: "relative", children: [_jsx("img", { src: imgSrcPathHelper(image), alt: translate("fp.gradableImage"), width: "80", height: "80" }), _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", style: styles.expandIconStyle, onClick: function () {
                                            handleOpenImage(imgSrcPathHelper(image));
                                        }, children: _jsx(AspectRatioIcon, { fontSize: "small" }) })] })] }), _jsxs(Grid, { container: true, xs: 8, paddingX: 1, children: [_jsx(Grid, { xs: 12, children: _jsx(Typography, { className: "small-text normal-bold", style: styles.commentTitle, children: translate("fp.comment") }) }), _jsx(Grid, { xs: 12, children: _jsx(TextField, { id: "outlined-basic", multiline: true, rows: 3, label: "", variant: "outlined", value: comment !== null && comment !== void 0 ? comment : "", onChange: function (e) {
                                        handelImageGrid(idx, "cmt", e.target.value);
                                    }, sx: styles.commentBlock }) }), _jsx(Grid, { className: "small-text", xs: 12, children: dragImgNameHelper(imgName) })] })] }))] }));
};
var UngradableImageGrid = function (_a) {
    var image = _a.image, comment = _a.comment, idx = _a.idx, id = _a.id, handelImageGrid = _a.handelImageGrid, isDragging = _a.isDragging, imgName = _a.imgName;
    var translate = useTranslate();
    return (_jsxs(_Fragment, { children: [isDragging && (_jsx(Draggable, { id: id, children: _jsxs(Grid, { container: true, xs: 12, padding: 1, margin: 1, style: __assign(__assign({}, styles.container), { width: "16rem" }), children: [_jsxs(Grid, { container: true, xs: 4, children: [_jsxs(Grid, { container: true, xs: 12, display: "flex", justifyContent: "end", paddingY: 0, marginY: 0, children: [_jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () {
                                                handelImageGrid(idx, "delete");
                                            }, style: styles.delButton, children: _jsx(DeleteIcon, { fontSize: "small" }) }), _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(ControlCameraIcon, { fontSize: "small" }) })] }), _jsx(Grid, { container: 12, display: "flex", flexWrap: "wrap", position: "relative", children: _jsx("img", { src: imgSrcPathHelper(image), alt: "Ungradable image", width: "80", height: "80" }) })] }), _jsxs(Grid, { xs: 8, paddingX: 2, children: [_jsx(Grid, { xs: 12, paddingY: 1, children: _jsx(Typography, { className: "small-text normal-bold", style: styles.commentTitle, children: translate("fp.comment") }) }), _jsx(Grid, { xs: 12, children: _jsx(TextField, { id: "outlined-basic", multiline: true, rows: 3, label: "", variant: "outlined", value: comment !== null && comment !== void 0 ? comment : "", onChange: function (e) {
                                            handelImageGrid(idx, "cmt", e.target.value);
                                        }, sx: styles.commentBlock }) }), _jsx(Grid, { className: "small-text", xs: 12, children: dragImgNameHelper(imgName) })] })] }) })), !isDragging && (_jsxs(Grid, { container: true, xs: 12, padding: 1, margin: 1, style: styles.container, children: [_jsxs(Grid, { container: true, xs: 4, children: [_jsxs(Grid, { container: true, xs: 12, display: "flex", justifyContent: "end", paddingY: 0, marginY: 0, children: [_jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () {
                                            handelImageGrid(idx, "delete");
                                        }, style: styles.delButton, children: _jsx(DeleteIcon, { fontSize: "small" }) }), _jsx(Draggable, { id: id, style: styles.dragButton, children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(ControlCameraIcon, { fontSize: "small" }) }) })] }), _jsx(Grid, { xs: 12, display: "flex", flexWrap: "wrap", position: "relative", children: _jsx("img", { src: imgSrcPathHelper(image), alt: translate("fp.ungradableImage"), width: "80", height: "80" }) })] }), _jsxs(Grid, { container: true, xs: 8, paddingX: 2, children: [_jsx(Grid, { xs: 12, paddingY: 1, children: _jsx(Typography, { className: "small-text normal-bold", style: styles.commentTitle, children: translate("fp.comment") }) }), _jsx(Grid, { xs: 12, children: _jsx(TextField, { id: "outlined-basic", multiline: true, rows: 3, label: "", variant: "outlined", value: comment !== null && comment !== void 0 ? comment : "", onChange: function (e) {
                                        handelImageGrid(idx, "cmt", e.target.value);
                                    }, sx: styles.commentBlock }) }), _jsx(Grid, { className: "small-text", xs: 12, children: dragImgNameHelper(imgName) })] })] }))] }));
};
export { GradableImageGrid, UngradableImageGrid };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { List, Datagrid, FunctionField, useDataProvider, useResourceContext, useTranslate, useNotify, useSidebarState } from 'react-admin';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography, useMediaQuery } from '@mui/material';
import { BarChart, Bar, PieChart, Pie, Cell, Tooltip, Legend, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import TaskIcon from '@mui/icons-material/Task';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import { dbDateHelper } from '../utils/helper';
var AllOrganizations = function (props) {
    var translate = useTranslate();
    var notify = useNotify();
    var dataProvider = useDataProvider();
    var resource = useResourceContext(props);
    var navigate = useNavigate();
    var location = useLocation();
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    var _a = useState({ data: [] }), AllOrgleftbar = _a[0], setAllOrgleftbar = _a[1];
    var _b = useState({ data: [] }), pie1 = _b[0], setPie1 = _b[1];
    var _c = useState({ data: [] }), pie2 = _c[0], setPie2 = _c[1];
    var _d = useState({ data: [] }), pie3 = _d[0], setPie3 = _d[1];
    var _e = useState({ data: [] }), pie4 = _e[0], setPie4 = _e[1];
    var _f = useState({ data: [] }), pie5 = _f[0], setPie5 = _f[1];
    var _g = useState({ data: [] }), pie6 = _g[0], setPie6 = _g[1];
    var _h = useState([
        { title: translate('pages.dashboard.title.totalSubjects'), value: 0, label: "Total Subjects" },
        { title: translate('pages.dashboard.title.totalExams'), value: 0, label: "Total Exams" },
        { title: translate('pages.dashboard.title.unfinishExams'), value: 0, percentage: "(14.5%)", label: "Unfinished Exams" },
        { title: translate('pages.dashboard.title.finishedExams'), value: 0, percentage: "(85.5%)", label: "Finished Exams" },
    ]), cards = _h[0], setCards = _h[1];
    var _j = useState([
        { title: translate('pages.dashboard.title.barChart'), headerColor: 'linear-gradient(135deg, #6a1b9a 0%, #9c27b0 50%, #d500f9 100%)' },
    ]), cards2 = _j[0], setCards2 = _j[1];
    var _k = useState([
        { title1: translate('pages.dashboard.title.pie1'), title2: translate('pages.dashboard.title.total'), pie: pie1 },
        { title1: translate('pages.dashboard.title.pie2'), title2: translate('pages.dashboard.title.total'), },
        { title1: translate('pages.dashboard.title.pie3'), title2: translate('pages.dashboard.title.total'), },
        { title1: translate('pages.dashboard.title.pie4'), title2: translate('pages.dashboard.title.total'), pie: pie1 },
        { title1: translate('pages.dashboard.title.pie5'), title2: translate('pages.dashboard.title.total'), },
        { title1: translate('pages.dashboard.title.pie6'), title2: translate('pages.dashboard.title.total'), },
    ]), cards3 = _k[0], setCards3 = _k[1];
    useEffect(function () {
        Promise.all([
            dataProvider.Card1(),
            dataProvider.Card2(),
            dataProvider.Card4(),
            dataProvider.AllOrgleftbar(),
            dataProvider.AllOrgPie1(),
            dataProvider.AllOrgPie2(),
            dataProvider.AllOrgPie3(),
            dataProvider.AllOrgPie4(),
            dataProvider.AllOrgPie5(),
            dataProvider.AllOrgPie6(),
        ])
            .then(function (_a) {
            var Card1Response = _a[0], Card2Response = _a[1], Card4Response = _a[2], AllOrgleftbarResponse = _a[3], AllOrgPie1Response = _a[4], AllOrgPie2Response = _a[5], AllOrgPie3Response = _a[6], AllOrgPie4Response = _a[7], AllOrgPie5Response = _a[8], AllOrgPie6Response = _a[9];
            setCards(function (prevCards) {
                var newCards = __spreadArray([], prevCards, true);
                newCards[0] = __assign(__assign({}, newCards[0]), { value: Card1Response.data });
                newCards[1] = __assign(__assign({}, newCards[1]), { value: Card2Response.data });
                newCards[2] = __assign(__assign({}, newCards[2]), { value: Card2Response.data - Card4Response.data });
                newCards[3] = __assign(__assign({}, newCards[3]), { value: Card4Response.data });
                return newCards;
            });
            var translatedAllOrgleftbarResponse = __spreadArray([], AllOrgleftbarResponse.data.data, true);
            translatedAllOrgleftbarResponse === null || translatedAllOrgleftbarResponse === void 0 ? void 0 : translatedAllOrgleftbarResponse.map(function (el, idx) {
                el.label = translate("params.dashboard.years.".concat(el.label));
            });
            setAllOrgleftbar({ data: translatedAllOrgleftbarResponse });
            //translate all pie datas
            setPie1(AllOrgPie1Response.data);
            var translatedAllOrgPie2Response = __spreadArray([], AllOrgPie2Response.data.data, true);
            translatedAllOrgPie2Response === null || translatedAllOrgPie2Response === void 0 ? void 0 : translatedAllOrgPie2Response.map(function (el, idx) {
                if (el.label == "N/A" || !el.label) {
                    return;
                }
                el.label = translate("pages.exam.fields.params.eye.".concat(el.label));
            });
            setPie2({ data: translatedAllOrgPie2Response });
            console.log(AllOrgPie1Response.data, '==AllOrgPie1Response.data');
            // setPie3(AllOrgPie3Response.data)
            var translatedAllOrgPie3Response = __spreadArray([], AllOrgPie3Response.data.data, true);
            translatedAllOrgPie3Response === null || translatedAllOrgPie3Response === void 0 ? void 0 : translatedAllOrgPie3Response.map(function (el, idx) {
                if (el.label == "N/A" || !el.label) {
                    return;
                }
                el.label = translate("pages.exam.fields.params.ai_model.".concat(el.label));
            });
            setPie3({ data: translatedAllOrgPie3Response });
            // setPie4(AllOrgPie4Response.data)
            var translatedAllOrgPie4Response = __spreadArray([], AllOrgPie4Response.data.data, true);
            translatedAllOrgPie4Response === null || translatedAllOrgPie4Response === void 0 ? void 0 : translatedAllOrgPie4Response.map(function (el, idx) {
                if (el.label == "N/A" || !el.label) {
                    return;
                }
                el.label = translate("params.subjects.".concat(el.label));
            });
            setPie4({ data: translatedAllOrgPie4Response });
            // setPie5(AllOrgPie5Response.data)
            var translatedAllOrgPie5Response = __spreadArray([], AllOrgPie5Response.data.data, true);
            translatedAllOrgPie5Response === null || translatedAllOrgPie5Response === void 0 ? void 0 : translatedAllOrgPie5Response.map(function (el, idx) {
                if (el.label == "N/A" || !el.label) {
                    return;
                }
                el.label = translate("params.subjects.".concat(el.label));
            });
            setPie5({ data: translatedAllOrgPie5Response });
            // setPie6(AllOrgPie6Response.data)
            var translatedAllOrgPie6Response = __spreadArray([], AllOrgPie6Response.data.data, true);
            translatedAllOrgPie6Response === null || translatedAllOrgPie6Response === void 0 ? void 0 : translatedAllOrgPie6Response.map(function (el, idx) {
                if (el.label == "N/A" || !el.label) {
                    return;
                }
                el.label = translate("params.dashboard.years.".concat(el.label));
            });
            setPie6({ data: translatedAllOrgPie6Response });
        })
            .catch(function (error) {
            console.error('Error fetching dashboard data:', error);
            localStorage.setItem("appBarOrg", "N/A");
            localStorage.setItem("userOrgId", "N/A");
            notify(translate('error.dashboard.fetchData'), {
                type: 'error',
            });
        });
    }, [dataProvider]);
    var pies = [pie1, pie2, pie3, pie4, pie5, pie6];
    var open = useSidebarState()[0];
    var isSmallAndHamburger = useMediaQuery("(max-width: 700px)") && open;
    var isSmallAndHamburger2 = useMediaQuery("(max-width: 850px)") && open;
    function getWindowDimensions() {
        var width = window.innerWidth, height = window.innerHeight;
        return {
            width: width,
            height: height
        };
    }
    function useWindowDimensions() {
        var _a = useState(getWindowDimensions()), windowDimensions = _a[0], setWindowDimensions = _a[1];
        useEffect(function () {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
            window.addEventListener('resize', handleResize);
            return function () { return window.removeEventListener('resize', handleResize); };
        }, []);
        return windowDimensions;
    }
    var winwidth = useWindowDimensions().width;
    return (_jsxs(Box, { padding: 2, children: [_jsx(Grid, { container: true, spacing: 1, marginBottom: 3, children: cards.map(function (card, index) { return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 3, children: _jsx(Card, { elevation: 5, style: {
                            padding: '15px',
                            background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '20px',
                            color: 'white',
                            boxShadow: '0 6px 12px rgba(33, 150, 243, 0.3)',
                            transition: 'all 0.3s ease-in-out',
                            transform: 'translateY(0)',
                        }, children: _jsxs(Grid, { container: true, style: {
                                height: '100%',
                                display: 'flex',
                                flexWrap: "wrap",
                                alignItems: 'center',
                                justifyContent: "space-between",
                            }, children: [_jsxs(Grid, { item: true, style: {
                                        width: '5em',
                                        height: '5em',
                                        // marginRight: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'rgba(255, 255, 255, 0.2)',
                                        borderRadius: '50%',
                                        padding: '15px'
                                    }, children: [card.label === "Total Subjects" && _jsx(PeopleIcon, { style: { fontSize: '50px', color: 'white' } }), (card.label === "Total Exams" || card.title === "Generated Reports") && _jsx(DescriptionIcon, { style: { fontSize: '50px', color: 'white' } }), card.label === "Unfinished Exams" && _jsx(RestorePageIcon, { style: { fontSize: '50px', color: 'white' } }), card.label === "Finished Exams" && _jsx(TaskIcon, { style: { fontSize: '50px', color: 'white' } })] }), _jsxs(Grid, { item: true, style: { width: '50%', textAlign: 'right', flexGrow: 1 }, children: [_jsx(Typography, { variant: "h6", style: { fontWeight: "normal" /*was 700*/, color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,0.1)' }, children: card.title }), _jsx(Typography, { variant: "h4", style: { color: 'white', fontWeight: 'bold', marginTop: '10px', textShadow: '2px 2px 4px rgba(0,0,0,0.2)' }, children: card.value })] })] }) }) }, index)); }) }), _jsxs(Grid, { container: true, spacing: 1, marginBottom: 3, children: [cards2.map(function (card, index) { return (_jsx(Grid, { item: true, xs: 12, 
                        // sm={isSmallAndHamburger2 ? 12 : 6} 
                        md: 6, children: _jsxs(Card, { style: { height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }, children: [_jsx(CardHeader, { title: card.title, style: {
                                        width: '100%',
                                        background: card.headerColor,
                                        color: 'white',
                                        padding: '10px',
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        // width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), (index === 0) && _jsx(CardContent, { style: { padding: '30px', height: winwidth / 3, maxHeight: '400px', minHeight: "300px", overflow: 'auto', width: "100%" }, children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, { data: AllOrgleftbar.data, children: [_jsx(XAxis, { dataKey: "label" }), _jsx(YAxis, {}), _jsx(Bar, { dataKey: "value", fill: "#8884d8" })] }) }) })] }) })); }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsxs(Card, { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }, children: [_jsx(CardHeader, { title: translate('pages.dashboard.title.redlist'), style: {
                                        background: 'linear-gradient(135deg, #D32F2F 0%, #C62828 100%)',
                                        color: 'white',
                                        padding: '10px',
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { style: { height: '332px', overflow: 'auto', width: '100%' }, children: _jsx(List, { resource: "dashboard/subject/highRisk/all", actions: false, children: _jsxs(Datagrid, { bulkActionButtons: false, style: { tableLayout: "fixed", minWidth: '100%' }, sx: {
                                                '& .RaDatagrid-row': rowStyle,
                                                '& .RaDatagrid-headerCell': rowCellStyle,
                                                '& .RaDatagrid-rowCell': rowCellStyle,
                                            }, children: [_jsx(FunctionField, { label: translate("pages.settings.fields.profile.info.name"), render: function (record) {
                                                        0.;
                                                        return (_jsx(Typography, { onClick: function () {
                                                                var state = { customId: record === null || record === void 0 ? void 0 : record.subject_customId };
                                                                // console.log('==nagivate state', state, record)
                                                                navigate("/admin/subjects", { state: state });
                                                            }, sx: { cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }, children: "".concat(record.subject_firstname, " ").concat(record.subject_lastname) }));
                                                    } }), _jsx(FunctionField, { source: "eye", label: translate("pages.exam.fields.eye"), render: function (record) {
                                                        return (_jsx(Typography, { className: "small-text", children: record.eye ? translate("params.exams.eye.".concat(record.eye)) : "" }));
                                                    } }), _jsx(FunctionField, { source: "examDate", label: translate("pages.exam.fields.exam_date"), render: function (record) {
                                                        return (_jsx(Typography, { children: "".concat(dbDateHelper(record.examDate)) }));
                                                    } }), _jsx(FunctionField, { source: "model", label: translate("pages.exam.fields.ai_model"), render: function (record) {
                                                        return (_jsx(Typography, { className: "small-text", children: record.model ? translate("pages.exam.fields.params.ai_model.".concat(record.model)) : "" }));
                                                    } })] }) }) }), _jsx(CardActions, { style: { justifyContent: 'flex-end', padding: '16px' }, children: _jsx(Button, { variant: "contained", color: "primary", style: {
                                            background: 'linear-gradient(135deg, #D32F2F 0%, #C62828 100%)',
                                            color: 'white',
                                            borderRadius: '8px',
                                            padding: '6px 12px',
                                            fontSize: '0.7rem',
                                            fontWeight: 'bold',
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                            transition: 'all 0.3s ease-in-out'
                                        }, onClick: function () { return window.open('/admin/subjects', '_blank'); }, onMouseEnter: function (e) {
                                            e.currentTarget.style.transform = 'scale(1.05)';
                                            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.3)';
                                        }, onMouseLeave: function (e) {
                                            e.currentTarget.style.transform = 'scale(1)';
                                            e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
                                        }, children: translate('pages.subject.ViewSubject') }) })] }) })] }), _jsxs(Grid, { container: true, spacing: 4, style: { marginTop: '0px' }, children: [_jsx(Grid, { item: true, xs: 12, sm: 4, children: _jsxs(Card, { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }, children: [_jsx(CardHeader, { title: _jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between', width: '100%' }, children: [_jsx("span", { children: translate('pages.dashboard.title.pie1') }), _jsx("span", { children: translate('pages.dashboard.title.total') + pie1.data.reduce(function (sum, item) { return sum + item.value; }, 0) })] }), style: {
                                        background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                                        color: 'white',
                                        padding: '10px',
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { children: pie1.data.every(function (item) { return item.value === 0; }) ? (_jsx("div", { style: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '300px',
                                            height: '298px',
                                            borderRadius: '50%',
                                            backgroundColor: '#f0f0f0',
                                        }, children: _jsx(Typography, { variant: "h6", align: "center", fontWeight: 900, children: "No data" }) })) : (_jsxs(PieChart, { width: 300, height: 300, children: [_jsx(Pie, { data: pie1.data.map(function (item) { return (__assign(__assign({}, item), { label: item.label === null || item.label === 'NULL' || item.label === undefined ? 'N/A' : item.label })); }), dataKey: "value", nameKey: "label", cx: "50%", cy: "50%", outerRadius: 80, fill: "#82ca9d", children: pie1.data.map(function (entry, index) { return (_jsx(Cell, { fill: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5] }, "cell-".concat(index))); }) }), _jsx(Tooltip, {}), _jsx(Legend, {})] })) })] }) }), _jsx(Grid, { item: true, xs: 12, sm: 4, children: _jsxs(Card, { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }, children: [_jsx(CardHeader, { title: _jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between', width: '100%' }, children: [_jsx("span", { children: translate('pages.dashboard.title.pie2') }), _jsx("span", { children: translate('pages.dashboard.title.total') + pie2.data.reduce(function (sum, item) { return sum + item.value; }, 0) })] }), style: {
                                        background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                                        color: 'white',
                                        padding: '10px',
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { children: pie2.data.every(function (item) { return item.value === 0; }) ? (_jsx("div", { style: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '300px',
                                            height: '298px',
                                            borderRadius: '50%',
                                            backgroundColor: '#f0f0f0',
                                        }, children: _jsx(Typography, { variant: "h6", align: "center", fontWeight: 900, children: "No data" }) })) : (_jsxs(PieChart, { width: 300, height: 300, children: [_jsx(Pie, { data: pie2.data.map(function (item) { return (__assign(__assign({}, item), { label: item.label === null || item.label === 'NULL' || item.label === undefined ? 'N/A' : item.label })); }), dataKey: "value", nameKey: "label", cx: "50%", cy: "50%", outerRadius: 80, fill: "#82ca9d", children: pie2.data.map(function (entry, index) { return (_jsx(Cell, { fill: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5] }, "cell-".concat(index))); }) }), _jsx(Tooltip, {}), _jsx(Legend, {})] })) })] }) }), _jsx(Grid, { item: true, xs: 12, sm: 4, children: _jsxs(Card, { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }, children: [_jsx(CardHeader, { title: _jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between', width: '100%' }, children: [_jsx("span", { children: translate('pages.dashboard.title.pie3') }), _jsx("span", { children: translate('pages.dashboard.title.total') + pie3.data.reduce(function (sum, item) { return sum + item.value; }, 0) })] }), style: {
                                        background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                                        color: 'white',
                                        padding: '10px',
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { children: pie3.data.every(function (item) { return item.value === 0; }) ? (_jsx("div", { style: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '300px',
                                            height: '298px',
                                            borderRadius: '50%',
                                            backgroundColor: '#f0f0f0',
                                        }, children: _jsx(Typography, { variant: "h6", align: "center", fontWeight: 900, children: "No data" }) })) : (_jsxs(PieChart, { width: 300, height: 300, children: [_jsx(Pie, { data: pie3.data.map(function (item) { return (__assign(__assign({}, item), { label: item.label === null || item.label === 'NULL' || item.label === undefined ? 'N/A' : item.label })); }), dataKey: "value", nameKey: "label", cx: "50%", cy: "50%", outerRadius: 80, fill: "#82ca9d", children: pie3.data.map(function (entry, index) { return (_jsx(Cell, { fill: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5] }, "cell-".concat(index))); }) }), _jsx(Tooltip, {}), _jsx(Legend, {})] })) })] }) }), _jsx(Grid, { item: true, xs: 12, sm: 4, children: _jsxs(Card, { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }, children: [_jsx(CardHeader, { title: _jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between', width: '100%' }, children: [_jsx("span", { children: translate('pages.dashboard.title.pie4') }), _jsx("span", { children: translate('pages.dashboard.title.total') + pie4.data.reduce(function (sum, item) { return sum + item.value; }, 0) })] }), style: {
                                        background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                                        color: 'white',
                                        padding: '10px',
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { children: pie4.data.every(function (item) { return item.value === 0; }) ? (_jsx("div", { style: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '300px',
                                            height: '298px',
                                            borderRadius: '50%',
                                            backgroundColor: '#f0f0f0',
                                        }, children: _jsx(Typography, { variant: "h6", align: "center", fontWeight: 900, children: "No data" }) })) : (_jsxs(PieChart, { width: 300, height: 300, children: [_jsx(Pie, { data: pie4.data.map(function (item) { return (__assign(__assign({}, item), { label: item.label === null || item.label === 'NULL' || item.label === undefined ? 'N/A' : item.label })); }), dataKey: "value", nameKey: "label", cx: "50%", cy: "50%", outerRadius: 80, fill: "#82ca9d", children: pie4.data.map(function (entry, index) { return (_jsx(Cell, { fill: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5] }, "cell-".concat(index))); }) }), _jsx(Tooltip, {}), _jsx(Legend, {})] })) })] }) }), _jsx(Grid, { item: true, xs: 12, sm: 4, children: _jsxs(Card, { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }, children: [_jsx(CardHeader, { title: _jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between', width: '100%' }, children: [_jsx("span", { children: translate('pages.dashboard.title.pie5') }), _jsx("span", { children: translate('pages.dashboard.title.total') + pie5.data.reduce(function (sum, item) { return sum + item.value; }, 0) })] }), style: {
                                        background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                                        color: 'white',
                                        padding: '10px',
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { children: pie5.data.every(function (item) { return item.value === 0; }) ? (_jsx("div", { style: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '300px',
                                            height: '298px',
                                            borderRadius: '50%',
                                            backgroundColor: '#f0f0f0',
                                        }, children: _jsx(Typography, { variant: "h6", align: "center", fontWeight: 900, children: "No data" }) })) : (_jsxs(PieChart, { width: 300, height: 300, children: [_jsx(Pie, { data: pie5.data.map(function (item) { return (__assign(__assign({}, item), { label: item.label === null || item.label === 'NULL' || item.label === undefined ? 'N/A' : item.label })); }), dataKey: "value", nameKey: "label", cx: "50%", cy: "50%", outerRadius: 80, fill: "#82ca9d", children: pie5.data.map(function (entry, index) { return (_jsx(Cell, { fill: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5] }, "cell-".concat(index))); }) }), _jsx(Tooltip, {}), _jsx(Legend, {})] })) })] }) }), _jsx(Grid, { item: true, xs: 12, sm: 4, children: _jsxs(Card, { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }, children: [_jsx(CardHeader, { title: _jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between', width: '100%' }, children: [_jsx("span", { children: translate('pages.dashboard.title.pie6') }), _jsx("span", { children: translate('pages.dashboard.title.total') + pie6.data.reduce(function (sum, item) { return sum + item.value; }, 0) })] }), style: {
                                        background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                                        color: 'white',
                                        padding: '10px',
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { children: pie6.data.every(function (item) { return item.value === 0; }) ? (_jsx("div", { style: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '300px',
                                            height: '298px',
                                            borderRadius: '50%',
                                            backgroundColor: '#f0f0f0',
                                        }, children: _jsx(Typography, { variant: "h6", align: "center", fontWeight: 900, children: "No data" }) })) : (_jsxs(PieChart, { width: 300, height: 300, children: [_jsx(Pie, { data: pie6.data.map(function (item) { return (__assign(__assign({}, item), { label: item.label === null || item.label === 'NULL' || item.label === undefined ? 'N/A' : item.label })); }), dataKey: "value", nameKey: "label", cx: "50%", cy: "50%", outerRadius: 80, fill: "#82ca9d", children: pie6.data.map(function (entry, index) { return (_jsx(Cell, { fill: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5] }, "cell-".concat(index))); }) }), _jsx(Tooltip, {}), _jsx(Legend, {})] })) })] }) })] })] }));
};
export default AllOrganizations;

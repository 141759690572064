var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import * as React from 'react';
import { Dialog, DialogActions, DialogContent, Zoom, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useNotify } from 'react-admin';
import { dialog } from '../utils/componentWizard';
import popupStyles from "../styles/popup/popup.module.css";
var classes = {
    noMarginDialog: {
        '&>div:nth-child(3)': {
            '&>div': {
                margin: 0,
            },
        },
    }
};
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
var CreateDialog = function (_a) {
    var opened = _a.opened, closeDialog = _a.closeDialog, reloadRecord = _a.reloadRecord, translate = _a.translate;
    var notify = useNotify();
    var valuesChecking = function (givenValues) {
        return givenValues.orgName && givenValues.orgName.trim() !== '';
    };
    return (_jsx(React.Fragment, { children: _jsxs(Dialog, { open: opened, TransitionComponent: Transition, onClose: closeDialog, className: classes.noMarginDialog, keepMounted: true, sx: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                '& .MuiDialog-paper': {
                    borderRadius: '30px',
                    backgroundColor: "#F5F5F5",
                    padding: "0.5em"
                },
            }, children: [_jsx(dialog.header, { title: "".concat(translate("dialog.Create"), " ").concat(translate("pages.organizations.name")), handleClose: closeDialog }), _jsx(Formik, { initialValues: { orgName: '' }, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                        var err_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 4, , 5]);
                                    if (!valuesChecking(values)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, reloadRecord(values, 'Create')];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 3];
                                case 2:
                                    notify(translate("pages.organizations.notify.enterGroup"));
                                    _a.label = 3;
                                case 3: return [3 /*break*/, 5];
                                case 4:
                                    err_1 = _a.sent();
                                    console.log('==err', err_1);
                                    return [3 /*break*/, 5];
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); }, children: function (props) {
                        var handleSubmit = props.handleSubmit;
                        return (_jsxs("form", { noValidate: true, onSubmit: handleSubmit, children: [_jsx(DialogContent, { className: popupStyles.dialogContent, children: _jsx(Grid, { container: true, display: "flex", flexWrap: "wrap", sx: {
                                            boxShadow: '0 0 5px gray',
                                            borderRadius: '20px',
                                            marginBottom: "0.7em",
                                            marginTop: "0.7em",
                                            padding: "0.5em",
                                            backgroundColor: "white"
                                        }, children: _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: 5, children: _jsx(Typography, { variant: "body1", padding: 2, sx: {
                                                            border: '0px solid',
                                                            fontWeight: 900,
                                                            fontSize: "0.9em",
                                                            opacity: 0.4,
                                                            textAlign: 'right'
                                                        }, children: translate("pages.organizations.name") }) }), _jsx(Grid, { xs: 7, children: _jsx(dialog.textField, { form: props, filters: [], setFilters: function () { }, label: "", name: "orgName", width: 200, required: true, xs: 8 }) })] }) }) }), _jsx(DialogActions, { children: _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: _jsx(dialog.submitButton, { isSubmitting: props.isSubmitting, text: translate("dialog.submit") }) }) })] }));
                    } })] }) }));
};
export default CreateDialog;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Layout, useSetLocale, useSidebarState } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { useEffect } from 'react';
export default (function (props) {
    var open = useSidebarState()[0];
    var setLocale = useSetLocale();
    useEffect(function () {
        var _a;
        var cachedAuth = (_a = JSON.parse(localStorage.getItem("auth"))) !== null && _a !== void 0 ? _a : {};
        if (cachedAuth) {
            setLocale(cachedAuth.language);
        }
    }, [setLocale]); // execute on mount
    return (_jsx(Layout, __assign({}, props, { appBar: AppBar, menu: Menu, sx: {
            '& .RaLayout-contentWithSidebar .MuiDrawer-root': {
                width: open ? 'auto' : 0,
                transition: 'width 1s ease'
            }
        } })));
});

var dummyData = {
    gradableFP: {
        left: [
            { name: "", image: "xxx", comment: "test left" },
            { name: "", image: "xxx", comment: "test left 2" },
        ],
        right: [
            { name: "", image: "xxx", comment: "" }
        ],
    },
    ungradableFP: [
        { image: "", comment: "failed 1 test" },
        { image: "", comment: "" },
    ]
};
var FPAIdummyData = {
    "analysisData": {
        "average_of_fold": 0.128474507914,
        "avg_score": 87.1525492086,
        "confidence_level": "≈60%",
        "folds": {
            "fold1": 0.00005873957,
            "fold2": 0.19748674,
            "fold3": 0.0830402,
            "fold4": 0.21780972,
            "fold5": 0.14397714
        },
        "folds_pred": {
            "fold1_pred": 1,
            "fold2_pred": 0,
            "fold3_pred": 1,
            "fold4_pred": 1,
            "fold5_pred": 0
        },
        "mode": "BE",
        "reverse": 0.871525492086,
        "risk_of_AD": "High",
        "score1": 49.8,
        "score2": 79.8,
        "sum_of_folds_pred": 3
    },
    "examData": {
        "FP": {
            "gradable": {
                "maculaCentered": {
                    "left": {
                        "comment": null,
                        "img": "https://i-cog.ai/uploads/1731634882985-291658746-APIOHA_20241024_101306_Non_myd_L_003.jpg.png"
                    },
                    "right": {
                        "comment": null,
                        "img": "https://i-cog.ai/uploads/1731634883004-129775636-APIOHA_20241024_101303_Non_myd_R_001.jpg.png"
                    }
                },
                "notMaculaCentered": {
                    "left": {
                        "comment": null,
                        "img": "https://i-cog.ai/uploads/1731634883013-856882274-APIOHA_20241024_101304_Non_myd_L_002.jpg.png"
                    },
                    "right": {
                        "comment": null,
                        "img": "https://i-cog.ai/uploads/1731634882995-518712363-APIOHA_20241024_101302_Non_myd_R_000.jpg.png"
                    }
                }
            }
        },
        "examId": "6667f66b-a142-45bc-9270-e12b12cd4739",
        "subjectId": "b79a4a1a-bf04-4482-891c-2b733c88fdc9"
    },
    "messages": [],
    "result": true,
    "avg_confidence_level": 67.5,
    "description": {
        "dementia": {
            "low": {
                "title": "LOW RISK",
                "description": "Your brain mimics brains of those with normal cognition."
            },
            "high": {
                "title": "NEEDS ATTENTION",
                "description": "You may have suboptimal brain health. Your brain may harbor features that are associated with Alzheimer's disease. It does not imply you have Alzheimer's disease."
            }
        },
        "AIAnalysis": {
            "low": [
                "We recommend you to continue a brain healthy lifestyle and have regular (e.g., yearly) brain health assessment.",
                "A brain healthy lifestyle reduces your chances of having Alzheimer’s disease. It includes optimizing/avoiding the following risk factors: hypertension, diabetes, hyperlipidaemia, obesity, smoking, physical and cognitive inactivity, social isolation, hearing loss, visual impairment, depression, unhealthy diet (e.g., high salt/sugar/fat, less vegetables/fruits/fish, excessive alcohol, fried/processed foods). ",
                "You can seek advice from related healthcare professionals if you are uncertain whether you have the above risk factors and whether your risk factors are under optimal control."
            ],
            "high": [
                "We recommend you to:",
                "1. Monitor whether you have cognitive symptoms. If you or your close relatives suspect you are already having cognitive symptoms (e.g.,repeat questions/statements, forget correct year/month/day of the week) you should seek medical assessment early.",
                "2. Review your control of the modifiable risk factors of Alzheimer’s disease and optimize those risk factors that are not well controlled. Modifiable risk factors may include hypertension, diabetes, hyperlipidaemia, obesity, smoking, physical and cognitive inactivity, social isolation, hearing loss, visual impairment, depression, and unhealthy diet (e.g., high salt/sugar/fat, less vegetables/fruits/fish, excessive alcohol, fried/processed foods). You should seek medical advice if you are uncertain whether you have the above risk factors and whether your risk factors are under optimal control."
            ],
            "recommend": [
                "1. Monitor whether you have cognitive symptoms. If you or your close relatives suspect you are already having cognitive symptoms (e.g., repeat questions/statements, forget correct year/month/day of the week) you should seek medical assessment early. ",
                "2. Review your control of the modifiable risk factors of Alzheimer’s disease and optimize those risk factors that are not well controlled. Modifiable risk factors may include hypertension, diabetes, hyperlipidaemia, obesity, smoking, physical and cognitive inactivity, social isolation, hearing loss, visual impairment, depression, and unhealthy diet (e.g., high salt/sugar/fat, less vegetables/fruits/fish, excessive alcohol, fried/processed foods). You should seek medical advice if you are uncertain whether you have the above risk factors and whether your risk factors are under optimal control. "
            ]
        },
        "attention": "You may have suboptimal brain health. Your brain may harbor features that are associated with Alzheimer’s disease. It does not imply you have Alzheimer’s disease. ",
        "remarks": [
            "Since false positive rate is around ",
            "if you have no apparent cognitive symptoms and have good control in most of the modifiable risk factors, please continue a brain healthy lifestyle. "
        ],
        "disclaimer": "The RetinAD version 1.0 (RetinAD1.0) test uses artificial intelligence (AI), specifically machine learning models, as a tool to analyze data and provide an estimate of the risk of Dementia associated with Alzheimer’s Disease and an estimate of Brain Health. Note that it was not designed to detect mild cognitive impairment associated with Alzheimer’s disease. AI technology assists by processing and interpreting complex data but does not replace the judgment of healthcare professionals. Please note that the RetinAD1.0 test is designed for initial risk assessment purposes only and is not a substitute for a definitive diagnostic procedure or clinical diagnosis. A comprehensive medical evaluation is necessary to diagnose Alzheimer’s disease. The results provided by the RetinAD1.0 test are probabilistic and should be interpreted as an estimation of risk rather than a definitive conclusion. The test may produce false positives or false negatives, and its accuracy is subject to the limitations inherent in AI-based assessments. If  you have concerns about your cognitive health (e.g., new onset cognitive symptoms), we strongly recommend scheduling an appointment with a qualified healthcare professional who can provide a full diagnostic evaluation and discuss your results in the context of a comprehensive medical examination. By undergoing the RetinAD1.0  test and accepting this report, you acknowledge that you have read, understood, and agreed to the terms of this disclaimer. "
    }
};
// const FPAIdummyData = {
//     "subjectInfos": {
//         "dialogType": "Edit",
//         "submit": null,
//         "id": "3e5530f4-b57e-4bfa-9920-eec2149e42b1",
//         "customId": "Z123456(1)",
//         "lastname": "陳",
//         "firstname": "大明",
//         "gender": "M",
//         "educationLevel": "10-12 yrs",
//         "dateOfBirth": "2024-08-21T17:58:53.000Z"
//     },
//     "examsInfos": {
//         "id": "3e5530f4-b57e-4bfa-9920-eec2fffb1",
//         "examDate": "2024-08-21T17:58:53.000Z",
//     },
//     "description": {
//         "AIAnalysis": {
//             "dementia": {
//                 low: {
//                     title: "LOW RISK",
//                     description: "Your brain mimics brains of those with normal cognition.",
//                 },
//                 high: {
//                     title: "NEEDS ATTENTION",
//                     description: `You may have suboptimal brain health. Your brain may harbor 
//                                             features that are associated with Alzheimer'sdisease.
//                                             It does not imply you have Alzheimer's disease.`,
//                 },
//             },
//             "description": {
//                 low:
//                     ["We recommend you to continue a brain healthy lifestyle and have regular (e.g., yearly) brain health assessment.",
//                         "A brain healthy lifestyle reduces your chances of having Alzheimer’s disease. It includes optimizing/avoiding the following risk factors: hypertension, diabetes, hyperlipidaemia, obesity, smoking, physical and cognitive inactivity, social isolation, hearing loss, visual impairment, depression, unhealthy diet (e.g., high salt/sugar/fat, less vegetables/fruits/fish, excessive alcohol, fried/processed foods). ",
//                         "You can seek advice from related healthcare professionals if you are uncertain whether you have the above risk factors and whether your risk factors are under optimal control."
//                     ],
//                 recommend: ["1. Monitor whether you have cognitive symptoms. If you or your close relatives suspect you are already having cognitive symptoms (e.g., repeat questions/statements, forget correct year/month/day of the week) you should seek medical assessment early. ",
//                     "2. Review your control of the modifiable risk factors of Alzheimer’s disease and optimize those risk factors that are not well controlled. Modifiable risk factors may include hypertension, diabetes, hyperlipidaemia, obesity, smoking, physical and cognitive inactivity, social isolation, hearing loss, visual impairment, depression, and unhealthy diet (e.g., high salt/sugar/fat, less vegetables/fruits/fish, excessive alcohol, fried/processed foods). You should seek medical advice if you are uncertain whether you have the above risk factors and whether your risk factors are under optimal control. "
//                 ],
//                 high:
//                     ["We recommend you to:",
//                         "1. Monitor whether you have cognitive symptoms. If you or your closerelatives suspect you are already having cognitive symptoms (e.g.,repeat questions/statements, forget correct year/month/day of theweek) you should seek medical assessment early.",
//                         "2. Review your control of the modifi able risk factors of Alzheimer’sdisease and optimize those risk factors that are not well controlled.Modifi able risk factors may include hypertension, diabetes,hyperlipidaemia, obesity, smoking, physical and cognitive inactivity,social isolation, hearing loss, visual impairment, depression, andunhealthy diet (e.g., high salt/sugar/fat, less vegetables/fruits/fi sh,excessive alcohol, fried/processed foods). You should seek medicaladvice if you are uncertain whether you have the above"
//                     ]
//             },
//             "recommend":
//                 ["1. Monitor whether you have cognitive symptoms. If you or your close relatives suspect you are already having cognitive symptoms (e.g., repeat questions/statements, forget correct year/month/day of the week) you should seek medical assessment early. ",
//                     "2. Review your control of the modifiable risk factors of Alzheimer’s disease and optimize those risk factors that are not well controlled. Modifiable risk factors may include hypertension, diabetes, hyperlipidaemia, obesity, smoking, physical and cognitive inactivity, social isolation, hearing loss, visual impairment, depression, and unhealthy diet (e.g., high salt/sugar/fat, less vegetables/fruits/fish, excessive alcohol, fried/processed foods). You should seek medical advice if you are uncertain whether you have the above risk factors and whether your risk factors are under optimal control. "]
//         },
//         "attention": "You may have suboptimal brain health. Your brain may harbor features that are associated with Alzheimer’s disease. It does not imply you have Alzheimer’s disease. ",
//         "remarks": ["Since false positive rate is around ",
//             "if you have no apparent cognitive symptoms and have good control in most of the modifiable risk factors, please continue a brain healthy lifestyle. "],
//         "disclaimer": "The RetinAD version 1.0 (RetinAD1.0) test uses artificial intelligence (AI), specifically machine learning models, as a tool to analyze data and provide an estimate of the risk of Dementia associated with Alzheimer’s Disease and an estimate of Brain Health. Note that it was not designed to detect mild cognitive impairment associated with Alzheimer’s disease. AI technology assists by processing and interpreting complex data but does not replace the judgment of healthcare professionals. Please note that the RetinAD1.0 test is designed for initial risk assessment purposes only and is not a substitute for a definitive diagnostic procedure or clinical diagnosis. A comprehensive medical evaluation is necessary to diagnose Alzheimer’s disease. The results provided by the RetinAD1.0 test are probabilistic and should be interpreted as an estimation of risk rather than a definitive conclusion. The test may produce false positives or false negatives, and its accuracy is subject to the limitations inherent in AI-based assessments. If  you have concerns about your cognitive health (e.g., new onset cognitive symptoms), we strongly recommend scheduling an appointment with a qualified healthcare professional who can provide a full diagnostic evaluation and discuss your results in the context of a comprehensive medical examination. By undergoing the RetinAD1.0  test and accepting this report, you acknowledge that you have read, understood, and agreed to the terms of this disclaimer. "
//     },
//     "analysisData": {
//         "average_of_fold": 0.9970219379999999,
//         "avg_score": 0.2978062000000059,
//         "confidence_level": "≈90%",
//         "folds": {
//             "fold1": 0.99701154,
//             "fold2": 0.99987555,
//             "fold3": 0.9997745,
//             "fold4": 0.9884493,
//             "fold5": 0.9999988
//         },
//         "folds_pred": {
//             "fold1_pred": 0,
//             "fold2_pred": 0,
//             "fold3_pred": 0,
//             "fold4_pred": 0,
//             "fold5_pred": 0
//         },
//         "mode": "BE",
//         "reverse": 0.002978062000000059,
//         "risk_of_AD": "High",
//         "score1": 16.1,
//         "score2": 50.2,
//         "sum_of_folds_pred": 0,
//         "messages": [
//             "1. Your brain mimics those with normal cognition. You should continue a brain-healthy lifestyle and have yearly brain health assessments.",
//         ]
//     },
//     "examData": {
//         "FP": {
//             "gradable": {
//                 "maculaCentered": {
//                     "left": {
//                         "comment": null,
//                         "img": newLeftEye
//                     },
//                     "right": {
//                         "comment": null,
//                         "img": newRightEye
//                     }
//                 },
//                 "notMaculaCentered": {
//                     "left": {
//                         "comment": null,
//                         "img": newLeftEye
//                     },
//                     "right": {
//                         "comment": null,
//                         "img": newRightEye
//                     }
//                 }
//             }
//         },
//         "examId": "rrr 8eb84404-f7bb-406f-a765-9553cfbe1c2e",
//         "subjectId": "d439de2d-20fc-48c6-8101-1c0402a820cb"
//     },
//     "messages": [],
//     "result": true,
// }
export default dummyData;
export { FPAIdummyData };

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// @ts-nocheck
import { EyeMuiChoices } from "../../Params";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
var analyzedResultTextCases = function (_a) {
    var result = _a.result, setFunc = _a.setFunc, translate = _a.translate;
    var resultInfos = [
        { type: "both", text: translate("pages.exam.fp.resTextCases.both"), bgColor: "green", icon: CheckCircleIcon },
        { type: "onlyright", text: translate("pages.exam.fp.resTextCases.onlyright"), bgColor: "#ecaa00", icon: WarningIcon },
        { type: "onlyleft", text: translate("pages.exam.fp.resTextCases.onlyleft"), bgColor: "#ecaa00", icon: WarningIcon },
        { type: "none", text: translate("pages.exam.fp.resTextCases.none"), bgColor: "#d93d3d", icon: ErrorIcon },
        { type: "toomuch", text: translate("pages.exam.fp.resTextCases.toomuch"), bgColor: "#d93d3d", icon: ErrorIcon },
    ];
    switch (result) {
        case "both":
            setFunc(resultInfos[0]);
            break;
        case "onlyright":
            setFunc(resultInfos[1]);
            break;
        case "onlyleft":
            setFunc(resultInfos[2]);
            break;
        case "none":
            setFunc(resultInfos[3]);
            break;
        case "toomuch":
            setFunc(resultInfos[4]);
            break;
    }
};
var updateAnalyzedResultTextCases = function (givenImgRow, setAnalyzedResultInfo, translate) {
    console.log(givenImgRow, '==givenImgRow');
    var checkGradResult = {
        leftMacula: 0,
        rightMacula: 0,
        leftDisc: 0,
        rightDisc: 0
    };
    var newGivenImgRow = __spreadArray([], givenImgRow, true);
    newGivenImgRow === null || newGivenImgRow === void 0 ? void 0 : newGivenImgRow.forEach(function (el) {
        if (el.gradable) {
            if (el.right && el.center) {
                checkGradResult.rightMacula += 1;
            }
            if (!el.right && el.center) {
                checkGradResult.leftMacula += 1;
            }
            if (el.right && !el.center) {
                checkGradResult.rightDisc += 1;
            }
            if (!el.right && !el.center) {
                checkGradResult.leftDisc += 1;
            }
        }
    });
    var textType;
    // Check for multiple images in any position
    if (checkGradResult.leftMacula > 1 || checkGradResult.rightMacula > 1 ||
        checkGradResult.leftDisc > 1 || checkGradResult.rightDisc > 1) {
        analyzedResultTextCases({ result: "toomuch", setFunc: setAnalyzedResultInfo, translate: translate });
        textType = null;
    }
    // Check if both eyes have complete sets
    else if (checkGradResult.leftMacula === 1 && checkGradResult.leftDisc === 1 &&
        checkGradResult.rightMacula === 1 && checkGradResult.rightDisc === 1) {
        textType = EyeMuiChoices[2].label;
        analyzedResultTextCases({ result: "both", setFunc: setAnalyzedResultInfo, translate: translate });
    }
    // Check if left eye has complete set
    else if (checkGradResult.leftMacula === 1 && checkGradResult.leftDisc === 1) {
        textType = EyeMuiChoices[0].label;
        analyzedResultTextCases({ result: "onlyleft", setFunc: setAnalyzedResultInfo, translate: translate });
    }
    // Check if right eye has complete set
    else if (checkGradResult.rightMacula === 1 && checkGradResult.rightDisc === 1) {
        textType = EyeMuiChoices[1].label;
        analyzedResultTextCases({ result: "onlyright", setFunc: setAnalyzedResultInfo, translate: translate });
    }
    // If no complete set is available
    else {
        analyzedResultTextCases({ result: "none", setFunc: setAnalyzedResultInfo, translate: translate });
        textType = null;
    }
    return textType;
};
//remove duplicate cmt
var removeDuplicateCommentRows = function (givenComments) {
    var comments = (JSON.parse(JSON.stringify(givenComments)));
    comments = comments.reverse();
    if (comments.length > 0) {
        // Create a map to store the latest comment for each unique id
        var uniqueCommentsMap_1 = new Map();
        // Iterate through the comments array to update the uniqueCommentsMap
        comments.forEach(function (comment) {
            // Check if the comment id already exists in the map
            if (uniqueCommentsMap_1.has(comment.id)) {
                // Compare the dates and update if the current comment is newer
                if (comment.date > uniqueCommentsMap_1.get(comment.id).date) {
                    uniqueCommentsMap_1.set(comment.id, comment);
                }
            }
            else {
                uniqueCommentsMap_1.set(comment.id, comment);
            }
        });
        // Get the unique and latest comments from the map
        var uniqueComments = Array.from(uniqueCommentsMap_1.values());
        return uniqueComments;
    }
    return comments;
};
export { updateAnalyzedResultTextCases, removeDuplicateCommentRows };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { dbDateHelperWithHHMM } from '../../utils/helper';
import { useDataProvider, useNotify, useRefresh, } from 'react-admin';
import { Typography, Grid, Zoom, Box, } from '@mui/material';
import { editComp, } from '../../utils/componentWizard';
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, setClickedPanelComp = _a.setClickedPanelComp, backgroundColor = _a.backgroundColor;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", padding: 0.5, style: {
            cursor: "pointer",
            backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white"
        }, onClick: function () { }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", children: text })] }));
};
var classes = {
    noMarginDialog: {
        '&>div:nth-child(3)': {
            '&>div': {
                margin: 0,
            },
        },
    }
};
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
var GeneralComp = function (_a) {
    var data = _a.data, params = _a.params, translate = _a.translate, filters = _a.filters, setFilters = _a.setFilters, closeDialog = _a.closeDialog, opened = _a.opened, reloadRecord = _a.reloadRecord, setDialogData = _a.setDialogData, acessControlList = _a.acessControlList;
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var location = useLocation();
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var handleSubmit = function () {
        var values = filters;
        if (valuesChecking(values)) {
            var newValues = __assign({}, values);
            if (newValues.email === originalInfo.email) {
                delete newValues.email;
            }
            ;
            if (newValues.username === originalInfo.username) {
                delete newValues.username;
            }
            ;
            reloadRecord(__assign({}, newValues), data === null || data === void 0 ? void 0 : data.dialogType);
        }
        else {
            notify(translate("common.fillInAll"), {
                type: 'warning',
            });
        }
    };
    return (_jsx(_Fragment, { children: _jsx(Box, { sx: { marginLeft: 2 }, children: _jsx(Grid, { container: true, spacing: 2, children: _jsxs(React.Fragment, { children: [_jsx(Grid, { item: true, xs: 2, children: _jsx(Typography, { variant: "body1", sx: {
                                    fontWeight: 900,
                                    fontSize: "0.9em",
                                    opacity: 0.4,
                                    textAlign: 'left',
                                    padding: 0
                                }, children: translate("pages.organizations.fields.orgId") }) }), _jsx(Grid, { item: true, xs: 10, children: _jsx(Box, { sx: { transform: 'translateY(-15px)' }, children: _jsx(editComp.DisabletextField, { filters: data, setFilters: setDialogData, fullWidth: true, label: "", name: "id", required: true, disabled: true }) }) }), _jsx(Grid, { item: true, xs: 2, children: _jsx(Typography, { variant: "body1", sx: {
                                    fontWeight: 900,
                                    fontSize: "0.9em",
                                    opacity: 0.4,
                                    textAlign: 'left'
                                }, children: translate("pages.organizations.fields.orgName") }) }), _jsx(Grid, { item: true, xs: 10, children: _jsx(Box, { sx: { transform: 'translateY(-15px)' }, children: _jsx(editComp.textField, { filters: data, setFilters: setDialogData, fullWidth: true, label: "", name: "orgName", required: true, disabled: !acessControlList.organization_admin.update &&
                                        (!acessControlList.organization_normal.update ||
                                            data.orgName !== (localStorage.getItem('appBarOrg') || '')) }) }) }), _jsx(Grid, { item: true, xs: 2, children: _jsx(Typography, { variant: "body1", sx: {
                                    fontWeight: 900,
                                    fontSize: "0.9em",
                                    opacity: 0.4,
                                    textAlign: 'left'
                                }, children: translate("pages.organizations.fields.createdAt") }) }), _jsx(Grid, { item: true, xs: 10, children: _jsx(Box, { sx: { transform: 'translateY(-15px)' }, children: _jsx(editComp.DisabletextField, { filters: data, setFilters: setDialogData, fullWidth: true, label: "", name: "createdAt", givenValue: dbDateHelperWithHHMM(data.createdAt), required: true, disabled: true }) }) }), _jsx(Grid, { item: true, xs: 2, children: _jsx(Typography, { variant: "body1", sx: {
                                    fontWeight: 900,
                                    fontSize: "0.9em",
                                    opacity: 0.4,
                                    textAlign: 'left',
                                }, children: translate("pages.organizations.fields.updatedAt") }) }), _jsx(Grid, { item: true, xs: 10, children: _jsx(Box, { sx: { transform: 'translateY(-15px)' }, children: _jsx(editComp.DisabletextField, { filters: data, setFilters: setDialogData, fullWidth: true, label: "", name: "updatedAt", givenValue: dbDateHelperWithHHMM(data.updatedAt), required: true, disabled: true }) }) })] }) }) }) }));
};
export default GeneralComp;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import * as React from 'react';
import PropTypes from 'prop-types';
import { Input as BaseInput } from '@mui/base/Input';
import { Box, styled } from '@mui/system';
import { Grid } from '@mui/material';
function OTP(_a) {
    var separator = _a.separator, length = _a.length, value = _a.value, onChange = _a.onChange;
    var inputRefs = React.useRef(new Array(length).fill(null));
    var focusInput = function (targetIndex) {
        var targetInput = inputRefs.current[targetIndex];
        targetInput.focus();
    };
    var selectInput = function (targetIndex) {
        var targetInput = inputRefs.current[targetIndex];
        targetInput.select();
    };
    var handleKeyDown = function (event, currentIndex) {
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case ' ':
                event.preventDefault();
                break;
            case 'ArrowLeft':
                event.preventDefault();
                if (currentIndex > 0) {
                    focusInput(currentIndex - 1);
                    selectInput(currentIndex - 1);
                }
                break;
            case 'ArrowRight':
                event.preventDefault();
                if (currentIndex < length - 1) {
                    focusInput(currentIndex + 1);
                    selectInput(currentIndex + 1);
                }
                break;
            case 'Delete':
                event.preventDefault();
                onChange(function (prevOtp) {
                    var otp = prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
                    return otp;
                });
                break;
            case 'Backspace':
                event.preventDefault();
                if (currentIndex > 0) {
                    focusInput(currentIndex - 1);
                    selectInput(currentIndex - 1);
                }
                onChange(function (prevOtp) {
                    var otp = prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
                    return otp;
                });
                break;
            default:
                break;
        }
    };
    var handleChange = function (event, currentIndex) {
        var currentValue = event.target.value;
        var indexToEnter = 0;
        while (indexToEnter <= currentIndex) {
            if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
                indexToEnter += 1;
            }
            else {
                break;
            }
        }
        onChange(function (prev) {
            var otpArray = prev.split('');
            var lastValue = currentValue[currentValue.length - 1];
            otpArray[indexToEnter] = lastValue;
            return otpArray.join('');
        });
        if (currentValue !== '') {
            if (currentIndex < length - 1) {
                focusInput(currentIndex + 1);
            }
        }
    };
    var handleClick = function (event, currentIndex) {
        selectInput(currentIndex);
    };
    var handlePaste = function (event, currentIndex) {
        var _a;
        event.preventDefault();
        var clipboardData = event.clipboardData;
        // Check if there is text data in the clipboard
        if (clipboardData.types.includes('text/plain')) {
            var pastedText = clipboardData.getData('text/plain');
            pastedText = pastedText.substring(0, length).trim();
            var indexToEnter = 0;
            while (indexToEnter <= currentIndex) {
                if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
                    indexToEnter += 1;
                }
                else {
                    break;
                }
            }
            var otpArray = value.split('');
            for (var i = indexToEnter; i < length; i += 1) {
                var lastValue = (_a = pastedText[i - indexToEnter]) !== null && _a !== void 0 ? _a : ' ';
                otpArray[i] = lastValue;
            }
            onChange(otpArray.join(''));
        }
    };
    return (_jsx(Box, { sx: { display: 'flex', gap: 1, alignItems: 'center', justifyContent: "space-between" }, children: new Array(length).fill(null).map(function (_, index) {
            var _a;
            return (_jsxs(React.Fragment, { children: [_jsx(BaseInput, { slots: {
                            input: InputElement,
                        }, "aria-label": "Digit ".concat(index + 1, " of OTP"), slotProps: {
                            input: {
                                ref: function (ele) {
                                    inputRefs.current[index] = ele;
                                },
                                onKeyDown: function (event) { return handleKeyDown(event, index); },
                                onChange: function (event) { return handleChange(event, index); },
                                onClick: function (event) { return handleClick(event, index); },
                                onPaste: function (event) { return handlePaste(event, index); },
                                value: (_a = value[index]) !== null && _a !== void 0 ? _a : '',
                            },
                        } }), index === length - 1 ? null : separator] }, index));
        }) }));
}
OTP.propTypes = {
    length: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    separator: PropTypes.node,
    value: PropTypes.string.isRequired,
};
var blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
};
var grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};
var InputElement = styled('input')(function (_a) {
    var theme = _a.theme;
    return "\n  width: 60px;\n  height: 60px;\n  font-family: 'IBM Plex Sans', sans-serif;\n  font-size: 1.2rem;\n  font-weight: 400;\n  line-height: 1.5;\n  padding: 8px 0px;\n  border-radius: 8px;\n  text-align: center;\n  color: ".concat(theme.palette.mode === 'dark' ? grey[300] : grey[900], ";\n  background: ").concat(theme.palette.mode === 'dark' ? grey[900] : '#fff', ";\n  border: 1px solid ").concat(theme.palette.mode === 'dark' ? grey[700] : grey[200], ";\n  box-shadow: 0px 2px 4px ").concat(theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)', ";\n\n  &:hover {\n    border-color: ").concat(blue[400], ";\n  }\n\n  &:focus {\n    border-color: ").concat(blue[400], ";\n    box-shadow: 0 0 0 3px ").concat(theme.palette.mode === 'dark' ? blue[600] : blue[200], ";\n  }\n\n  // firefox\n  &:focus-visible {\n    outline: 0;\n  }\n");
});
export default function OTPInput(_a) {
    // const [otp, setOtp] = React.useState('');
    var otp = _a.otp, setOtp = _a.setOtp;
    return (_jsx(Grid, { item: true, display: 'flex', flexWrap: "wrap", justifyContent: "center", marginY: 2, children: _jsx(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 2 }, children: _jsx(OTP, { separator: _jsx("span", {}), value: otp, onChange: setOtp, length: 6 }) }) }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import englishMessages from "ra-language-english";
var customEnglishMessages = __assign(__assign({}, englishMessages), { error: {
        common: {
            tryAgain: "Error: Please try again",
            getFailed: "Error: Fetch data failed",
        },
        subject: {
            subId: "Error: A subject with this id already exists in this organization, please use another unique subject id",
            org: "Error: This organization id does not belong to any organizations",
            NotFoundException: "Error: Subject with ID is not found",
            delFailed: "Error: Subject delete failed",
            dupId: "Error: A subject with this id already exists in this organization, please use another unique subject id",
        },
        exam: {
            delFailed: "Error: Exam delete failed"
        },
        ACL: {
            getACLFailed: "Error: Get ACL failed",
        },
        login: {
            tryAgain: "Error: Please try again",
            invalidToken: "Error: Incorrect 2FA code. Please check and try again.",
            accNotAct: "Error: Account is not activated",
            PWNotSame: "Error: Passwords do not match. Please ensure both passwords are identical.",
            email: "Error: Please enter a valid email address",
            sameEmail: "Error: email already used, please try another one",
            allSame: "Error: email or username already used, please try another one",
            emailNotExist: "Error: This email address does not belong to any registered user. Please check the email or register a new account.",
            invalidCredentials: "Error: Invalid credentials",
            registerFailed: "Error: Register failed, invalid credentials",
            setNewPWFailed: "Error: set new password failed, invalid credentials",
            enterPW: "Please enter the password",
            failSendEmail: "Error: Failed to send email",
            emailFieldEmpty: "Error: Email field cannot be empty. Please enter your email address.",
        },
        dashboard: {
            fetchData: "Error: error in fetching dashboard data",
        },
        settings: {
            dupEmailUsn: "Error: Settings update fail, duplicate email or username",
            curPW: "Error: Current password is incorrect",
            newPW: "Error: New password not match",
            PW: "Error: Settings update fail, please double check the password",
            noUser: "Error: User not found",
        },
        fp: {
            uploadFailed: "Error: FP images upload failed",
            fpAnlysis: "Error: Error in exam analysis, please update the images",
            updateFailed: "Error: Images update fail",
            uploadPDFFailed: "Error: PDF upload failed",
            notFound: "Error: FP image id not found",
            examNotFound: "Error: Exam not found",
            aiclassify: "Fail to classify images using Convnext Model, please upload the appropriate images",
            AI: {
                subId: "Error: Invalid subject id",
                examId: "Error: Invalid exam id",
            },
        },
        dataExport: {
            exportFail: "Error: Data export fail",
        },
        userList: {
            createFail: "Error: User create failed",
            createDupFail: "Error: User create failed, duplicate email or username",
            updateFail: "Error: User update failed, duplicate email or username",
            getFail: "Error: get data failed",
            disableFail: "Error: disable user failed",
            invalidBoth: "Error: invalid data of userGroups or Organizations",
            invalidOrg: "Error: invalid data of organizations",
            invalidUserGroups: "Error: invalid data of user groups",
            noUserID: "Error: No user has this user id",
        },
        organization: {
            getUserOrgFailed: "Error: get user organization failed",
            dupEmailUsn: "Error: User update fail, duplicate email or username",
            createFailed: "Error: Organization create failed",
            updateFailed: "Error: Organization update failed",
            delFailed: "Error: Organization delete failed",
            getParamsFailed: "Error: Get organization params failed",
            validOrg: "Error: Please enter valid organization name",
            nameUsed: "Error: this organization name is already being used, please consider use a different name",
            noId: "Error: This organization id does not belong to any organizations",
            notOpen: "Error: this organization is not opened for everyone",
            userGroup: {
                updateFail: "Error: User group update fail",
                createFail: "Error: User group create fail",
                delFail: "Error: User group delete fail",
                noUserId: "Error: This user id does not belong to any users",
                wrongGroup: "Error: Some of the user groups do not belong to this organization",
                sameName: "Error: There is user group using same name in this organization",
                notFound: "Error: User group not found",
                notBelong: "Error: This user does not belong to this organization",
            },
        },
    }, login: {
        notify: {
            digitCode: "6-digit code sent",
            resetSuccess: "Reset password success",
        },
        signIn: "Sign In",
        register: "Register",
        YourEmail: "Your Email",
        resend: "Resend",
        emailSent: "Email sent",
        username: "Username",
        email: "Email",
        authenCode: "6-digit Authentication Code",
        didntReceive: "Didn't receive?",
        password: "Password",
        AI_Screening_Platform: "AI Screening Platform",
        forgetPW: {
            caption: {
                willSendEmail: "An email will be sent to your inbox to initiate the password reset process.",
                sentEmail: "An email has been sent to your inbox. Please check your email to continue the password reset process.",
                notReceiveEmail: "Not receiving the email?",
                willSend2fa: "An email with a time-limited 6-digit authentication code will be sent to your inbox. Please enter the code to sign in."
            },
        },
        resetPW: {
            caption: {
                inputPW: "Please input new password."
            },
        },
        SignUpNow: "Sign up now",
        ForgetYourPassword: "Forget your password?",
        ForgetPassword: "Forget Password",
        ResetPassword: "Reset Password",
        YourPassword: "Your Password",
        YourUsername: "Your Username",
        ConfirmPassword: "Confirm Password",
        BackToSignIn: "Back to Sign In",
    }, breadcrumb: {
        main: "Main",
        userManagement: "User Management",
    }, fp: {
        left_eye: "Left Eye",
        right_eye: "Right Eye",
        macula_centered: "Macula-centered",
        disc_centered: "Disc-centered",
        gradableFp: "Gradable FP",
        ungradableFp: "Ungradable FP / Unclassified Area",
        comment: "Comment (if any)",
        ungradableImage: "Ungradable image",
        gradableImage: "Gradable image",
        fundusPhotos: "Fundus Photos",
        pleaseUploadTheFundusPhotos: "Please upload the fundus photos.",
        dragImagesHere: "Drag images here (Max 10)",
        dragImagesHere2: "Drag images here",
        supportedFileType: "Supported file type: .jpg, .png",
        fileSize: "File size: up to 10 MB",
        quotaLeft: "Quota left",
        notify: {
            uploaded: "Files uploaded",
            updated: "Images updated",
        },
        /*  message:{
           both: "Both the right eye and the left eye can be analyzed.",
           onlyright: "(✓RE ✘LE) The right eye can be analyzed. The left eye cannot be analyzed due to missing or ungradable images.",
           onlyleft: "(✘RE ✓LE) The left eye can be analyzed. The right eye cannot be analyzed due to missing or ungradable images.",
           none: "Neither eye can be analyzed. Please ensure you have uploaded one gradable Macula-centered and one gradable Disc-centered image for at least one eye.",
           toomuch: "Multiple gradable images detected for the same position. Please keep only one gradable image per position (Macula/Disc) per eye.",
         } */
    }, pagniation: {
        rowsPerPage: "Rows per page: ",
        "of": "of"
    }, components: {
        ai_results: "AI Results",
        data_import: "Data Import",
    }, tooltip: {
        viewSubject: "View Subject",
        viewExam: "View Exam",
        viewUser: "View User",
        editSubject: "Edit Subject",
        editExam: "Edit Exam",
        editUser: "Edit User",
        editAccessRight: "Edit Access Right",
        editGroup: "Edit Group",
        deleteSubject: "Delete Subject",
        deleteExam: "Delete Exam",
        deleteUser: "Delete User",
        deleteOrg: "Delete Organization",
        deleteGroup: "Delete Group",
        importData: "Import Data",
        viewAndEditOrg: "View and Edit Organization",
        viewOrg: "View Organization",
        checkOutUser: "Checkout User",
        disableUser: "Disable User",
    }, comfirmDialog: {
        subject: "Are you sure you want to delete this subject?",
        exam: "Are you sure you want to delete this exam?",
        user: "Are you sure you want to delete this user?",
        organization: "Are you sure you want to delete this organization?",
        group: "Are you sure you want to delete this group?",
        disableUser: "Are you sure you want to disable this user?",
    }, pos: {
        search: "Search",
        configuration: "Configuration",
        language: "Language",
        theme: {
            name: "Theme",
            light: "Light",
            dark: "Dark",
        },
        menu: {
            files: "Files",
            main: "Main",
            userManagement: "User Management",
        },
    }, params: {
        exams: {
            eye: {
                Left: "Left",
                Right: "Right",
                Both: "Both",
            },
            outcome: {
                high: "High",
                High: "High",
                Low: "Low",
                null: "Pending",
            },
            status: {
                Pending: "Pending",
                "In progress": "In progress",
                Completed: "Completed",
            },
        },
        subjects: {
            unkown: "Unknown",
            yrs: "yrs",
            M: "M",
            F: "F",
            "Prefer not to disclose": "Prefer not to disclose",
            // edu lvl
            'Unknown': 'Unknown',
            '0-3 yrs': '0-3 yrs',
            '4-6 yrs': '4-6 yrs',
            '7-9 yrs': '7-9 yrs',
            '10-12 yrs': '10-12 yrs',
            '> 12 yrs': '> 12 yrs',
            //status
        },
        users: {
            Active: "Active",
            active: "Active",
            disabled: "Disabled",
            Disabled: "Disabled",
            'Not activate': "Not activate"
        },
        dashboard: {
            years: {
                '0-20y': '0-20y',
                '20-40y': '20-40y',
                '40-60y': "40-60y",
                '60-80y': "60-80y",
                '>80y': ">80y",
            },
            paymentRecord: {
                Pending: "Pending",
                Completed: "Completed",
            },
        },
        settings: {
            package: {
                'Advanced package': "Advanced package",
                'Standard package': "Standard package",
                'Basic package': "Basic package",
            }
        },
    }, common: {
        search: "Search",
        save: "Save",
        analyze: "Assess brain health",
        close: "Close",
        confirm: "Confirm",
        back: "Back",
        selectAll: "Select All",
        unselectAll: "RESET",
        fillInAll: "Please fill in all information",
        classify: "Evaluate image quality",
    }, dialog: {
        export: "Export",
        exportSubjects: "Export Subjects",
        submit: "Submit",
        submit_and_import: "Submit & Import Data",
        Edit: "Edit",
        Create: "Create",
    }, pages: {
        common: {
            selection: {
                select_all: "Select all",
                reset: "Reset",
                select: "Select",
            },
        },
        postPayment: {
            paymentSuccess: "✔ Payment Successful",
            paymentFail: "✘ Payment failed",
            loading: "Loading...",
            backToSubjects: "Back To Subjects",
            processingPayment: "Processing Payment",
            paymentStatus: "Payment Status",
            successMessage: "Payment successful! Pakage purchased: ",
            failureMessage: "Payment failed.",
            pleaseWait: "Please wait...",
            leftQuota: "Quota left: ",
        },
        subject: {
            name: "Subject",
            title: "Subject",
            ViewSubject: "View Subject",
            notify: {
                updated: "Subject updated",
                updateFailed: "Error: Subject update failed",
                get: "Subject fetched",
                getFailed: "Error: Subject fetched failed",
                added: "Subject added",
                addFailed: "Error: Subject create failed",
                deleted: "Subject deleted",
            },
            fields: {
                info: "Subject Information",
                customId: "ID",
                id: "Subject ID",
                firstname: "First name",
                lastname: "Last name",
                gender: "Gender",
                dateOfBirth: "Date of birth",
                dateOfBirthFrom: "Date of birth - From",
                dateOfBirthTo: "Date of birth - To",
                educationLevel: "Education level",
                actions: "Actions",
                examFromDate: "Exam - From",
                examToDate: "Exam - To",
                analyzeFromDate: "Analyze - From",
                analyzeToDate: "Analyze - To",
            },
            addNew: "Add new subject",
        },
        exam: {
            name: "Exam",
            title: "Exam",
            allExamDate: "All Exam Date",
            notify: {
                updated: "Exam updated",
                updateFailed: "Error: Exam update failed",
                get: "Exam fetched",
                getFailed: "Exam fetched failed",
                added: "Exam added",
                addFailed: "Exam create failed",
                deleted: "Exam deleted",
            },
            fields: {
                params: {
                    eye: {
                        Left: "Left",
                        Right: 'Right',
                        Both: "Both"
                    },
                    ai_model: {
                        'FP': 'FP',
                        'OCT': 'OCT',
                        'FP + OCT': 'FP + OCT'
                    },
                    risk_of_ad: {
                        'High': 'High',
                        'Low': 'Low',
                    },
                    status: {
                        'Pending': 'Pending',
                        'In progress': 'In progress',
                        'Completed': 'Completed'
                    },
                },
                exam_date: "Exam date",
                ai_model: "AI model",
                model: "Model",
                eye: "Eye",
                risk_of_ad: "Outcome",
                confidence_level: "Confidence level",
                confidence_level_avg: "Confidence level Average",
                status: "Status",
                analysisDate: "Analysis Date",
                actions: "Actions",
                customId: "ID",
                subject_id: "Subject ID",
                id: "Exam ID",
                firstname: "First name",
                lastname: "Last name",
                gender: "Gender",
                dateOfBirth: "Date of birth",
                educationLevel: "Education level",
                examFromDate: "Exam - From",
                examToDate: "Exam - To",
                analyzeFromDate: "Analyze - From",
                analyzeToDate: "Analyze - To",
            },
            dialog: {
                edit_exam: "Edit Exam ",
                add_new_exam: "Add New Exam",
            },
            fp: {
                analyzeCases: {
                    cases: "Analyzable cases:",
                    both: "1. Both eyes: Macula-centered and Disc-centered left & right eye images ",
                    leftOnly: "2. Left eyes only:  Macula-centered and Disc-centered left eye images",
                    rightOnly: "3. Right eyes only:  Macula-centered and Disc-centered right eye images",
                },
                resTextCases: {
                    "both": "Both the right eye and the left eye can be analyzed.",
                    "onlyright": "(✓RE ✘LE )The left eye cannot be analyzed due to the absence of macula-centered image and ungradable disc-centered image.",
                    "onlyleft": "(✘RE ✓LE )The right eye cannot be analyzed due to the absence of macula-centered image and ungradable disc-centered image.",
                    "none": "Neither the right eye nor the left eye can be analyzed. Please upload another set of images.",
                    "toomuch": "Only one gradable Macula-centered of left or right eye and one gradable Disc-centered of left or right eye will be use for analyze. Please update the images' positioning.",
                },
            },
        },
        dataExport: {
            name: "Data Export",
            criteria: "Data Export Criteria",
            notify: {
                select: "Please select field(s)",
                exported: "Data exported",
            },
            fields: {
                group_subjects: "Subjects Summary",
                subject_information: "Subject Information",
                images: "Images",
                fps: "FPs",
                fp: "FP",
                oct_reports: "Oct reports",
                retinAD_reports: "RetinAD reports",
                oct: "OCT",
                fp_oct: "FP + OCT",
                retinAD_probability_scores: "RetinAD probability scores",
                internal_use: "Internal use only",
                questionnaire_results: "Questionnaire results",
                examDate: "Exam",
                analyzeDate: "Analyze",
                dateFrom: "From",
                dateTo: "To",
            },
            dialog: {
                subjects_selected: "No. of subject(s) selected: ",
            },
        },
        settings: {
            name: "Settings",
            notify: {
                updated: "Settings updated",
                newPW: "Please enter new password",
                curPW: "Please enter current password",
                newPWNotSame: "New password is not the same as confirm new password",
            },
            leftCol: {
                profile: "Profile",
                language: "Language",
                security: "Security",
                membership: "Membership",
                payment: "Payment",
                package: "Package",
            },
            fields: {
                profile: {
                    headers: {
                        info: "General Information",
                        set_new_pw: "Set New Password",
                        access: "View Your Access Right",
                        accessRight: "Access Right",
                    },
                    info: {
                        id: "ID",
                        user_id: "User ID",
                        user_group: "User group",
                        username: "Username",
                        name: "Name",
                        firstname: "First name",
                        lastname: "Last name",
                        gender: "Gender",
                        email: "Email",
                    },
                    password: {
                        current: "Current PW",
                        new: "New Password",
                        confirm_new: "Confirm New PW",
                    },
                    access: {
                        dashboard: "Dashboard",
                        user_group: "User Group",
                        subject: "Subject",
                        data_export: "Data Export",
                    },
                    crud: {
                        c: "Add",
                        r: "View",
                        u: "Edit",
                        d: "Delete",
                    },
                },
                language: {
                    lan_caption: "Change language display setting",
                },
                security: {
                    header2fa: "Two Factor Authentication",
                    caption2fa: "Adds an extra security step to login, requiring a time-limited 6-digit code sent to your device to verify it's you.",
                },
            },
        },
        membership: {
            btn: {
                cancel: "Cancel",
                renew: "Renew",
                subsMonth: "Subscribe (Month)",
                subsYear: "Subscribe (Year)",
                purchase: "Purchase",
            },
            fields: {
                plans: {
                    genLeft: " generations left",
                    currentPlan: "Current Plan",
                    advanced: "Advanced",
                    monthly: "Monthly",
                    yearly: "Yearly",
                    caption: "* Unless you choose to cancel your subscription, your membership will automatically renew upon expiration.",
                },
                headers: {
                    current: "Current Plan",
                    basic: "Basic",
                    standard: "Standard",
                    advanced: "Advanced",
                    premium: "Premium",
                },
                details: {
                    subsPreTermsAndCond: "By subscribing, you agree to our ",
                    termsAndCond: " Terms & Conditions.",
                    unlimited: "Unlimited",
                    genMonthly: "AI report generations",
                    day: "Day",
                    month: "Month",
                    year: "Year",
                    save2monthfee: "saving 2 months of fees",
                },
            },
        },
        payment: {
            fields: {
                addNew: "Add new card",
                addNewPackage: "Add new package",
            }
        },
        userList: {
            name: "User",
            listName: "User List",
            ViewUser: "View User",
            notify: {
                delete: "User deleted",
                added: "User created",
                updated: "User updated",
                get: "Users fetched",
                disabled: "Disabled user",
                fillInUserGroup: "Please fill in user group",
            },
            fields: {
                id: "User ID",
                userGroup: "User group",
                username: "Username",
                organization: "Organization",
                email: "Email",
                status: "Status",
                createdAt: "Created at",
                updatedAt: "Updated at",
                pw: "Password",
                confirmpw: "Confirm PW",
            },
            addNew: "Add new user",
            info: "User Information",
        },
        organizations: {
            name: "Organizations",
            notify: {
                delete: "Organization deleted",
                create: "Organization created",
                update: "Organization updated",
                get: "Organizations fetched",
                enterGroup: "Please enter a group name",
                userGroup: {
                    update: "Updated user group(s)",
                    add: "Created user group",
                    delete: "Deleted user group"
                }
            },
            editComp: {
                general: "General",
                groups: "Groups",
                users: "Users",
                group: "Group",
            },
            fields: {
                orgId: "Org ID",
                id: "Group ID",
                userGroup: "User group",
                username: "Username",
                organization: "Organization",
                orgName: "Org name",
                groupNum: "Number of Groups",
                userNum: "Number of Users",
                groupName: "Group name",
                email: "Email",
                status: "Status",
                createdAt: "Created at",
                updatedAt: "Updated at",
            },
            availableUsersInOrg: "Available Users in Org ",
            noUsersInOrganization: "No users in organization",
            userIn: "Users in ",
            selected: "selected",
            addNew: "Add new organization",
            addNewGroup: "Add new group",
            addNewUser: "Create New User For This Organization",
            info: "User Information",
            groupList: {
                fields: {
                    name: "Group name",
                },
            },
            userList: {
                group: "Group ",
                accessRight: "Access Right ",
                filters: { userId: "User ID" },
                fields: {
                    name: {
                        dashboard: "Dashboard",
                        user: "User",
                        organization: "Organization",
                        subjects: "Subjects",
                        dataExport: "Data Export",
                    },
                    dashboard: {
                        gen: "View General Analysis",
                        sales: "View Sales Analysis",
                    },
                    user: {
                        viewAll: "View all users",
                        viewOrg: "View organization users",
                        create: "Create new users",
                        edit: "Edit all users",
                        editOrg: "Edit organization users",
                        disable: "Disable users",
                    },
                    organization: {
                        viewAll: "View all organizations",
                        viewOwn: "View your organization",
                        create: "Create new organization",
                        disable: "Disable all organizations",
                        disableOwnOrg: "Disable your organization",
                        edit: "Edit all organizations",
                        editOwn: "Edit your organization",
                        editGroup: "Edit all organization groups",
                        editOwnGroup: "Edit your groups",
                        addGroup: "Add new groups",
                        disableGroup: "Disable all groups",
                        disableOwnGroup: "Disable your groups",
                        disableOrg: "Disable organization users",
                        createNewOrg: "Create new organization users",
                    },
                    subjects: {
                        view: "View all subjects",
                        viewOrg: "View organization subjects",
                        create: "Create new subjects",
                        edit: "Edit subjects",
                        delete: "Delete subjects",
                    },
                    dataExport: {
                        all: "Export data of all subjects",
                        org: "Export data of organization subjects",
                        retinAD: "Export RetinAD probability scores",
                    },
                },
            },
        },
        package: {
            fields: {
                name: "Name",
                price: "Price",
                quota: "Quota",
                description: "Description",
            }
        },
        dashboard: {
            name: "Dashboard",
            amount: "1 review |||| %{smart_count} reviews",
            relative_to_poster: "Review on poster",
            detail: "Review detail",
            fields: {
                customer_id: "Customer",
                command_id: "Order",
                product_id: "Product",
                date_gte: "Posted since",
                date_lte: "Posted before",
                date: "Date",
                comment: "Comment",
                rating: "Rating",
            },
            action: {
                accept: "Accept",
                reject: "Reject",
            },
            notification: {
                approved_success: "Review approved",
                approved_error: "Error: Review not approved",
                rejected_success: "Review rejected",
                rejected_error: "Error: Review not rejected",
                noData: "No data",
            },
            title: {
                allOrganizations: "All Organizations",
                yourOrganization: "Your Organization",
                revenueReport: "Revenue Report",
                totalSubjects: "Total Subjects",
                totalExams: "Total Exams",
                generatedReports: "Generated Reports",
                advancedYearly: "Advanced - Yearly",
                unfinishExams: "Unfinished Exams",
                finishedExams: "Finished Exams",
                cbu: "Created by you",
                iuo: "In your organization",
                until20241231: "Until 2024/12/31",
                barChart: "Total Subjects and High-Risk Subjects by Age Group in all organization",
                redlist: "Recent High Risk Subjects in all organization",
                pie1: "Confidence level distribution in finished exams:",
                pie2: "Eye distribution in finished exams:",
                pie3: "AI model distribution in finished exams:",
                pie4: "Gender distribution:",
                pie5: "Education level distribution:",
                pie6: "Age Group distribution:",
                total: "Total:",
            },
        },
    }, version: "Version", acl: {
        organization_admin: {
            create: "Create organization",
            view: "View organization",
            update: "Edit organization",
            delete: "Delete organization",
        },
        organization_normal: {
            view: "View Organization",
            update: "Edit Organization",
            delete: "Delete Organization",
        },
        user_admin: {
            create: "Create user",
            view: "View user",
            update: "Edit user",
            delete: "Delete user",
        },
        user_normal: {
            create: "Create user",
            view: "View user",
            update: "Edit user",
            delete: "Delete user",
        },
        subject_admin: {
            create: "Create subject",
            view: "View subject",
            update: "Edit subject",
            delete: "Delete subject",
        },
        subject_normal: {
            create: "Create subject",
            view: "View subject",
            update: "Edit subject",
            delete: "Delete subject",
        },
        user_group_admin: {
            create: "Create user group",
            view: "View user group",
            update: "Edit user group",
            delete: "Delete user group",
        },
        user_group_normal: {
            create: "Create user group",
            view: "View user group",
            update: "Edit user group",
            delete: "Delete user group",
        },
        data_export_admin: {
            export: "Export data",
        },
        data_export_normal: {
            export: "Export data",
            export_retinad_probability_scores: "Export RetinAD",
        },
        dashborad_normal: {
            view: "View dashboard"
        },
        dashborad_admin: {
            view: "View dashboard and revenue report"
        },
        package_admin: {
            create: "create package",
            update: "update package",
            delete: "delete package",
        },
        header: {
            Organization1: "Organization (Admin)",
            Organization2: "Organization (Normal)",
            User1: "User (Admin)",
            User2: "User (Normal)",
            Subject1: "Subject (Admin)",
            Subject2: "Subject (Normal)",
            UserGroup1: "User Group (Admin)",
            UserGroup2: "User Group (Normal)",
            DataExport1: "Data Export (Admin)",
            DataExport2: "Data Export (Normal)",
            Dashboard1: "Dashboard (Admin)",
            Dashboard2: "Dashboard (Normal)",
            Payment: "Payment (Admin)"
        },
        remind: {
            title: "ACL (Access Control List) Roles !",
            content1: "1. Admin: Has full access rights across all organizations in the system.",
            content2: "2. Normal: Has full access rights within the organization they belong to.",
            content3: "Admin role grants system-wide permissions, while Normal role limits access to a user's specific organization.",
        }
    }, paymentRecord: {
        transactionRecord: "Transaction Record",
        transactionDate: "Transaction Date",
        transactionId: "Transaction ID",
        product: "Brought Product",
        status: "Status",
        quotaLeft: "Quota Left",
        buyerInformation: "Buyer Information",
    } });
export default customEnglishMessages;

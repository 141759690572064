var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslate } from "react-admin";
import { Typography, Grid, } from "@mui/material";
var ImageDrop = function (_a) {
    var selectedRow = _a.selectedRow, setSelectedRow = _a.setSelectedRow, filters = _a.filters, setFilters = _a.setFilters, onLoaded = _a.onLoaded, haveUploads = _a.haveUploads, curRowImgs = _a.curRowImgs;
    var styles = {
        container: {
            textAlign: "center",
            padding: 20,
            marginTop: 12,
            color: "black",
            fontSize: 18,
            fontWeight: 600,
            borderRadius: 4,
            userSelect: "none",
            outline: 0,
            cursor: "pointer",
            width: "100%",
            height: "100%"
            // backgroundColor: "blue"
        },
    };
    var onDrop = useCallback(function (acceptedFiles, rejectedFiles) {
        //get all files
        onLoaded(acceptedFiles);
    }, []);
    var _b = useDropzone({
        onDrop: onDrop,
        accept: {
            "image/jpeg": [],
            "image/png": [],
        },
    }), acceptedFiles = _b.acceptedFiles, fileRejections = _b.fileRejections, getRootProps = _b.getRootProps, getInputProps = _b.getInputProps, isDragAccept = _b.isDragAccept, isDragReject = _b.isDragReject;
    var translate = useTranslate();
    return (_jsx(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", style: {
            cursor: "pointer",
            border: "1px dashed",
            borderRadius: "10px",
        }, children: _jsxs("div", __assign({}, getRootProps(), { style: styles.container, children: [_jsx("input", __assign({}, getInputProps())), _jsx(Grid, { item: true, xs: 12, paddingY: 0.2, height: "100%", backgroundColor: "", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", children: _jsxs(Grid, { item: true, children: [!haveUploads && (_jsx(Typography, { className: "small-text", children: translate("fp.dragImagesHere") })), haveUploads && (_jsx(Typography, { className: "small-text", height: "100%", children: translate("fp.dragImagesHere") })), haveUploads && (_jsx(Typography, { className: "small-text", children: "".concat(10 - curRowImgs.length, " ").concat(translate("fp.quotaLeft")) })), _jsx(Grid, { item: true, xs: 12, paddingY: 0.2, children: _jsx(Typography, { className: "small-text", color: "grey", children: translate("fp.supportedFileType") }) }), _jsx(Grid, { item: true, xs: 12, paddingY: 0.2, children: _jsx(Typography, { className: "small-text", color: "grey", children: translate("fp.fileSize") }) })] }) })] })) }));
};
export default ImageDrop;

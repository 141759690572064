var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Typography, Grid, FormControlLabel, Switch, } from '@mui/material';
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, setClickedPanelComp = _a.setClickedPanelComp, backgroundColor = _a.backgroundColor;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", padding: 0.5, style: {
            cursor: "pointer",
            backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white"
        }, onClick: function () { }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", children: text })] }));
};
var SecurityComp = function (_a) {
    var params = _a.params, translate = _a.translate, filters = _a.filters, setFilters = _a.setFilters;
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var location = useLocation();
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var handleControlLabel = function (e) {
        setFilters(__assign(__assign({}, filters), { security: e.target.checked }));
    };
    return (_jsx(_Fragment, { children: filters && _jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", justifyContent: "space-between", height: "430px", sx: { marginLeft: '0.05em' }, children: [_jsx(Grid, { item: true, xs: 8.5, children: _jsx(Typography, { variant: "h6", sx: { fontSize: '1.1em', fontWeight: 'normal' }, children: translate("pages.settings.fields.security.header2fa") }) }), _jsx(Grid, { item: true, justifyContent: "right", children: _jsx(FormControlLabel, { labelPlacement: "start", control: _jsx(Switch, { checked: filters.security, 
                            // checked={enabled}
                            onChange: handleControlLabel, color: "primary" }), label: "" }) }), _jsx(Grid, { item: true, sm: 10, xs: 12, children: _jsx(Typography, { variant: "body1", sx: { fontSize: '1.1em', fontWeight: 'normal', color: 'gray' }, align: 'justify', children: translate("pages.settings.fields.security.caption2fa") }) }), _jsx(Grid, { item: true, height: "398px" })] }) }));
};
export default SecurityComp;

var rightPanelstyles = {
    dataExportContainer: {
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        marginLeft: "1rem",
        marginBottom: "1rem",
        padding: "1rem",
        borderRadius: "20px",
        border: "1px solid #ddd",
        // boxShadow: "2px 2px 5px lightgrey, 0 8px 10px -1px rgba(0,0,0,0.3)",
        // "-moz-box-shadow": "2px 2px 5px lightgrey, 0 8px 10px -5px rgba(0,0,0,0.3)",
        // "-webkit-box-shadow":
        //   "2px 2px 5px lightgrey, 0 8px 10px -5px rgba(0,0,0,0.3)",
    },
};
var dataImportstyles = {
    dataExport: {
        // backgroundColor: "red",
        // paddingY: "200px",
        width: "100%",
        // overflowY: "scroll"
    },
    btn: {
        background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
        color: "white",
        height: 60,
        padding: "0 30px",
        transition: "all 0.3s ease-in-out",
        position: "relative",
        overflow: "hidden",
        marginRight: "1em",
        "&:hover": {
            background: "linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)",
            transform: "translateY(-3px) scale(1.05)",
            boxShadow: "0 6px 20px rgba(33, 203, 243, .5)",
        },
        "&::after": {
            content: '""',
            position: "absolute",
            top: "-50%",
            left: "-50%",
            width: "200%",
            height: "200%",
            background: "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)",
            transform: "rotate(30deg)",
            animation: "shine 3s infinite linear",
        },
        "@keyframes shine": {
            "0%": { transform: "translateX(-100%) rotate(30deg)" },
            "100%": { transform: "translateX(100%) rotate(30deg)" },
        },
    },
};
export { rightPanelstyles, dataImportstyles };

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { DatagridConfigurable, DateField, List, TextField, useListContext, FunctionField, } from 'react-admin';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useMediaQuery, Typography, Grid, Button, Checkbox, } from "@mui/material";
import { useDispatch } from "react-redux";
import "../styles/global.module.css";
var TableDatagrid = function (_a) {
    var _b, _c;
    var selectedAll = _a.selectedAll, setSelectedAll = _a.setSelectedAll, translate = _a.translate, data = _a.data, setData = _a.setData, isLoading = _a.isLoading, educationLevelArray = _a.educationLevelArray, dataProvider = _a.dataProvider, refresh = _a.refresh, notify = _a.notify, resource = _a.resource, navigate = _a.navigate, handleBulkSelection = _a.handleBulkSelection, previewExport = _a.previewExport, acessControlList = _a.acessControlList, dataGridHeader = _a.dataGridHeader;
    // const { filterValues, setFilters, displayedFilters, sort } = listContext;
    // console.log(sort, '==listContext')
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    var dispatch = useDispatch();
    var selectedIds = useListContext().selectedIds;
    console.log(acessControlList, '==acessControlList 123');
    return (_jsx(Fragment, { children: _jsx(List, { resource: resource, actions: _jsx(_Fragment, {}), sort: { field: 'id', order: 'DESC' }, pagination: false, children: _jsxs(DatagridConfigurable, { style: { tableLayout: "fixed" }, sx: {
                    '& .RaDatagrid-row': rowStyle,
                    '& .RaDatagrid-headerCell': rowCellStyle,
                    '& .RaDatagrid-rowCell': rowCellStyle,
                }, bulkActionButtons: false, isLoading: isLoading, data: data, children: [(((_b = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.data_export_normal) === null || _b === void 0 ? void 0 : _b.export) || ((_c = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.data_export_admin) === null || _c === void 0 ? void 0 : _c.export)) && _jsx(FunctionField, { source: "id", sortable: false, label: "", render: function (record) {
                            var _a, _b, _c, _d, _e;
                            var appBarOrg = (_a = localStorage.getItem("appBarOrg")) !== null && _a !== void 0 ? _a : "";
                            console.log("appBarOrg==", appBarOrg, (_b = record === null || record === void 0 ? void 0 : record.organization) === null || _b === void 0 ? void 0 : _b.orgName);
                            return (_jsx(_Fragment, { children: _jsx(Grid, { item: true, md: 3, xs: 4, display: "flex", flexWrap: "wrap", justifyContent: "center", children: ((((_c = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.data_export_normal) === null || _c === void 0 ? void 0 : _c.export) === true && appBarOrg === ((_d = record === null || record === void 0 ? void 0 : record.organization) === null || _d === void 0 ? void 0 : _d.orgName)) || ((_e = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.data_export_admin) === null || _e === void 0 ? void 0 : _e.export)) ?
                                        _jsx(Checkbox, { sx: {
                                                backgroundColor: "",
                                                transform: "translatex(2rem)",
                                            }, checked: record.selected, onChange: function (e) {
                                                handleBulkSelection(false, record, record.selected, e.target.checked);
                                            } }) :
                                        _jsx(DisabledByDefaultIcon, { sx: { marginLeft: 9.9, color: '#d50000', borderRadius: '30px' } }) }) }));
                        } }), _jsx(FunctionField, { source: "customId", label: translate("pages.subject.fields.customId"), sortable: false, render: function (record) {
                            return (_jsx(Typography, { className: 'small-text', children: !record.isHeader ? record.customId : record.customId }));
                        } }), _jsx(TextField, { className: 'small-text', source: "lastname", sortable: false, label: translate("pages.subject.fields.lastname") }), _jsx(TextField, { className: 'small-text', source: "firstname", sortable: false, label: translate("pages.subject.fields.firstname") }), _jsx(FunctionField, { source: "gender", sortable: false, label: translate("pages.subject.fields.gender"), render: function (record) {
                            var displayText = translate("params.subjects.".concat(record.gender));
                            return (_jsx(Typography, { className: "small-text", children: displayText }));
                        } }), _jsx(DateField, { className: 'small-text', source: "dateOfBirth", sortable: false, label: translate("pages.subject.fields.dateOfBirth") }), _jsx(FunctionField, { source: "educationLevel", sortable: false, label: translate("pages.subject.fields.educationLevel"), render: function (record) {
                            var bgColor;
                            var displayText = translate("params.subjects.".concat(record.educationLevel));
                            switch (record.educationLevel) {
                                case educationLevelArray[0]:
                                    bgColor = "#aad5fd";
                                    break;
                                case educationLevelArray[1]:
                                    bgColor = "#6bb0eb";
                                    break;
                                case educationLevelArray[2]:
                                    bgColor = "#3d7ebe";
                                    break;
                                case educationLevelArray[3]:
                                    bgColor = "#0d5da8";
                                    break;
                                case educationLevelArray[4]:
                                    bgColor = "#004587";
                                    break;
                                case educationLevelArray[5]:
                                    bgColor = "#3d648a";
                                    break;
                            }
                            return (_jsx(Button, { disabled: true, className: "small-text-button", sx: {
                                    backgroundColor: bgColor,
                                }, children: displayText }));
                        } })] }) }) }));
};
export default TableDatagrid;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Grid } from '@mui/material';
import { SubscriptionBar, SubscriptionCard } from '../helper/PackageComps';
import { loadStripe } from '@stripe/stripe-js';
var stripePromise = loadStripe("pk_test_ju6veMmqd5eDMe1XhQVPyze2");
var PackageComp = function (_a) {
    var params = _a.params, translate = _a.translate, filters = _a.filters, setFilters = _a.setFilters, handleSubscribe = _a.handleSubscribe, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading, openEditDialog = _a.openEditDialog, reloadRecord = _a.reloadRecord, closeDialog = _a.closeDialog, acl = _a.acl;
    var _b = useState([]), subCard = _b[0], setSubCard = _b[1];
    var dataProvider = useDataProvider();
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data, translatedSubCard, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, dataProvider.GetProducts()];
                    case 1:
                        data = _a.sent();
                        console.log("Fetched products data:", data);
                        translatedSubCard = __spreadArray([], data.data, true);
                        translatedSubCard.map(function (el, idx) {
                            // set the default package translated name
                            if (el.name == 'Advanced package' || el.name == 'Standard package' || el.name == 'Basic package') {
                                el.name = translate("params.settings.package.".concat(el.name));
                            }
                        });
                        setSubCard(translatedSubCard);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error("Error fetching products:", error_1);
                        notify("error", { type: 'error' });
                        return [2 /*return*/, Promise.reject(error_1)];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, [dataProvider]);
    var notify = useNotify();
    var refresh = useRefresh();
    var handleControlLabel = function (e) {
        setFilters(__assign(__assign({}, filters), { security: e.target.checked }));
    };
    var deleteProduct = function (key) {
        dataProvider.DeleteProduct(key).then(function () {
            notify("Package deleted");
            setSubCard(subCard.filter(function (product) { return product.key !== key; })); // no need to refresh, you can immediately remove the product from the list
        });
    };
    return (_jsx(_Fragment, { children: filters &&
            _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "start", children: [_jsx(SubscriptionBar, { gens: filters === null || filters === void 0 ? void 0 : filters.gens, translate: translate }), _jsx(Grid, { item: true, display: "flex", flexWrap: "wrap", xs: 12, marginY: 2, gap: 1, children: Array.isArray(subCard) && subCard.map(function (el, idx) {
                            return (_jsx(SubscriptionCard, { translate: translate, el: el, idx: idx, handleSubscribe: handleSubscribe, isLoading: isLoading, setIsLoading: setIsLoading, deleteProduct: deleteProduct, openEditDialog: openEditDialog, reloadRecord: reloadRecord, closeDialog: closeDialog, acl: acl }));
                        }) })] }) }));
};
export default PackageComp;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Datagrid, FunctionField, List, TextField, useDataProvider, useResourceContext, useTranslate } from 'react-admin';
import { dbDateHelper } from '../utils/helper';
import { Button, Chip, IconButton, useMediaQuery } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
var PaymentRecord = function (props) {
    var translate = useTranslate();
    var dataProvider = useDataProvider();
    var resource = useResourceContext(props);
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    return (_jsx(Grid, { padding: 2, children: _jsxs(Card, { style: {
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '20px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
            }, children: [_jsx(CardHeader, { title: _jsx(Box, { sx: { display: 'flex', justifyContent: 'center', width: '100%' }, children: _jsx("span", { children: translate('paymentRecord.transactionRecord') }) }), style: {
                        background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                        color: 'white',
                        padding: '10px',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { style: {
                        width: '100%',
                        padding: 0,
                    }, children: _jsx(List, { resource: "payment/records", sx: {
                            '& .RaList-main': {
                                padding: '16px 24px',
                                backgroundColor: '#fff',
                            },
                            '& .MuiTableHead-root': {
                                backgroundColor: '#f5f5f5',
                                '& .MuiTableCell-head': {
                                    fontWeight: 600,
                                    color: '#424242',
                                    fontSize: '0.875rem',
                                    padding: '12px 16px',
                                }
                            },
                            '& .MuiTableBody-root': {
                                '& .MuiTableRow-root': {
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                        transition: 'background-color 0.2s ease',
                                    },
                                    '& .MuiTableCell-body': {
                                        padding: '16px',
                                        borderBottom: '1px solid #e0e0e0',
                                        fontSize: '0.875rem',
                                    }
                                },
                                '& .MuiTableRow-root:last-child .MuiTableCell-body': {
                                    borderBottom: 'none'
                                }
                            }
                        }, children: _jsxs(Datagrid, { style: { width: '100%', tableLayout: "fixed" }, sx: {
                                '& .RaDatagrid-row': rowStyle,
                                '& .RaDatagrid-headerCell': rowCellStyle,
                                '& .RaDatagrid-rowCell': rowCellStyle,
                            }, bulkActionButtons: false, children: [_jsx(FunctionField, { label: translate('paymentRecord.transactionId'), render: function (record) { return (_jsxs(Box, { sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1
                                        }, children: [_jsx(Box, { sx: {
                                                    width: '10em',
                                                    overflow: 'auto',
                                                    whiteSpace: 'nowrap',
                                                    border: '1px solid #e0e0e0',
                                                    borderRadius: '4px',
                                                    padding: '4px 8px',
                                                    '&::-webkit-scrollbar': {
                                                        height: '4px'
                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: '#888',
                                                        borderRadius: '4px'
                                                    }
                                                }, children: _jsx(Typography, { children: record.id }) }), _jsx(IconButton, { size: "small", onClick: function () {
                                                    navigator.clipboard.writeText(record.id);
                                                    // Optional: Add toast notification for feedback
                                                }, sx: {
                                                    padding: '4px',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                                    }
                                                }, children: _jsx(ContentCopyIcon, { fontSize: "small" }) })] })); } }), _jsx(FunctionField, { label: translate('paymentRecord.transactionDate'), render: function (record) {
                                        return (_jsx(Typography, { children: "".concat(dbDateHelper(record.created_at)) }));
                                    } }), _jsx(TextField, { source: "product", label: translate('paymentRecord.product') }), _jsx(FunctionField, { label: translate('paymentRecord.status'), render: function (record) { return (_jsx(Button, { variant: "contained", size: "small", color: record.status === 'Completed' ? 'success' : 'error', sx: {
                                            minWidth: '100px',
                                            textTransform: 'capitalize',
                                            borderRadius: '10px'
                                        }, children: translate("params.dashboard.paymentRecord.".concat(record.status)) })); } }), _jsx(FunctionField, { label: translate('paymentRecord.buyerInformation'), render: function (record) { return (_jsxs(Box, { sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1
                                        }, children: [_jsx(Typography, { children: record.buyer.username }), _jsx(Chip, { label: record.organization.orgName, size: "medium", sx: {
                                                    backgroundColor: '#e3f2fd',
                                                    color: '#1976d2',
                                                    fontWeight: 600,
                                                    borderRadius: '16px',
                                                    marginTop: '0.2em', // Moved down a little bit
                                                } })] })); } })] }) }) })] }) }));
};
export default PaymentRecord;

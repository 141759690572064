var rightPanelstyles = {
    dataExportContainer: {
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        margin: "1rem",
        padding: "0.5rem",
        borderRadius: "15px",
        boxShadow: "5px 5px lightgrey",
        '-moz-box-shadow': '0 0 3px lightgrey',
        '-webkit-box-shadow': '0 0 3px lightgrey',
        'box-shadow': '0 0 3px lightgrey',
    },
};
var orgStyles = {
    textBreakWord: {
        wordBreak: "break-word",
    },
    componentContainer: {
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        // margin: "1rem",
        marginRight: "1rem",
        padding: "1rem",
        borderRadius: "15px",
        boxShadow: "5px 5px lightgrey",
        '-moz-box-shadow': '0 0 3px lightgrey',
        '-webkit-box-shadow': '0 0 3px lightgrey',
        'box-shadow': '0 0 3px lightgrey',
        // backgroundColor: "red",
    },
    org: {
        // backgroundColor: "red",
        // paddingY: "200px",
        width: "100%",
        overflowY: "scroll"
    },
    saveText: {
        paddingRight: '10px',
        color: "#1976D2", // Blue color
        cursor: "pointer",
        fontWeight: "bold",
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        transition: "all 0.3s ease",
        marginBottom: "1em",
        position: "relative",
        '&:hover': {
            color: "#1565C0",
        },
        '&::before': {
            content: '""',
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "0",
            height: "0",
            backgroundColor: "rgba(25, 118, 210, 0.1)",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
            transition: "width 0.3s ease, height 0.3s ease",
        },
        '&:hover::before': {
            width: "150%",
            height: "150%",
        },
    },
    groupFilterText: {
        fontWeight: 900,
        fontSize: "0.9em",
        opacity: 0.4,
        textAlign: 'left'
    },
    settingsContainer: {
        width: "100%",
        minHeight: "42rem",
        // backgroundColor: "#fafbfc"
        // backgroundColor: "white"
        marginTop: "1rem"
    },
    editDialog: {
        boxShadow: '0 0 5px gray',
        borderRadius: '20px',
        marginBottom: "0.7em",
        padding: "0.5em",
        backgroundColor: "white"
    },
    saveBtn: {
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
        color: 'white',
        height: 60,
        padding: '0 30px',
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
            background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
            transform: 'translateY(-3px) scale(1.05)',
            boxShadow: '0 6px 20px rgba(33, 203, 243, .5)',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '-50%',
            left: '-50%',
            width: '200%',
            height: '200%',
            background: 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)',
            transform: 'rotate(30deg)',
            animation: 'shine 3s infinite linear',
        },
        '@keyframes shine': {
            '0%': { transform: 'translateX(-100%) rotate(30deg)' },
            '100%': { transform: 'translateX(100%) rotate(30deg)' },
        },
    }
};
export { rightPanelstyles, orgStyles };

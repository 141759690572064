var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, Card, CardActions, CircularProgress, Grid, IconButton, Typography, } from "@mui/material";
import { required, TextInput, useTranslate, } from "react-admin";
import Box from "@mui/material/Box";
// import LoginLogo from '../assets/logos/login logo.png'
import ICSLogo from "../../assets/logos/ics logo.png";
import "../../styles/global.module.css";
import { Visibility, VisibilityOff } from '@mui/icons-material';
var SignIn = function (_a) {
    var loading = _a.loading, signInInputs = _a.signInInputs, setSignInInputs = _a.setSignInInputs, setCurComp = _a.setCurComp, isMobileScreen = _a.isMobileScreen, isTabScreen = _a.isTabScreen;
    var _b = useState(false), showPassword = _b[0], setShowPassword = _b[1];
    var translate = useTranslate();
    console.log(showPassword);
    var cardWidth = "40%";
    switch (true) {
        case isMobileScreen:
            cardWidth = "75%";
            break;
        case isTabScreen:
            cardWidth = "60%";
            break;
    }
    return (_jsx(Box, { sx: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "flex-start",
            background: "#ebeff2",
            backgroundSize: "cover",
        }, children: _jsxs(Card, { sx: {
                background: "white",
                width: cardWidth,
                maxWidth: "27rem",
                marginTop: "3em",
                borderRadius: "40px",
                boxShadow: "20px 20px 25px rgba(77, 106, 149, 0.5)", // Light blue color
            }, children: [_jsx(Box, { sx: {
                        margin: "1em",
                        display: "flex",
                        justifyContent: "center",
                    }, children: _jsx("img", { src: ICSLogo, alt: "logo", height: 135, style: {
                            transition: "transform 0.8s ease-in-out",
                        }, onMouseEnter: function (e) {
                            e.currentTarget.style.transform = "scale(2)";
                        }, onMouseLeave: function (e) {
                            e.currentTarget.style.transform = "scale(1)";
                        } }) }), _jsx(Box, { sx: {
                        marginTop: "1em",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        color: function (theme) { return theme.palette.grey[500]; },
                    }, children: _jsx(Grid, { container: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", style: { backgroundColor: "" }, children: _jsxs(Grid, { item: true, xs: 4, sm: 6, md: 8, lg: 10, xl: 12, minWidth: "100%", children: [_jsx(Typography, { className: "large-bold responsive-text", textAlign: "center", color: "black", style: {
                                        fontSize: '1.3rem',
                                        wordWrap: 'break-word'
                                    }, width: '100%', children: translate("login.AI_Screening_Platform") }), _jsx(Typography, { className: "normal-bold responsive-text", textAlign: "center", color: "black", style: {
                                        wordWrap: 'break-word',
                                    }, children: translate("login.signIn") })] }) }) }), _jsxs(Box, { sx: { padding: "0 1em 1em 1em" }, children: [_jsxs(Box, { sx: { marginTop: "0em" }, children: [_jsx(Typography, { variant: "body1", className: "small-text large-bold", color: "#4d6a95", sx: { margin: "0px 0px 0px 10px" }, children: translate("login.username") }), _jsx(TextInput, { autoFocus: true, variant: "outlined", source: "username", label: false, onChange: function (e) {
                                        setSignInInputs(__assign(__assign({}, signInInputs), { username: e.target.value }));
                                    }, disabled: loading, validate: required(), fullWidth: true, placeholder: translate("login.YourUsername"), sx: {
                                        "& .MuiInputBase-root": {
                                            borderRadius: "10px",
                                            height: "3rem",
                                            border: "1px solid #ccc",
                                        },
                                    } })] }), _jsxs(Box, { sx: { marginTop: "0em" }, children: [_jsx(Typography, { className: "small-text large-bold", color: "#4d6a95", sx: { margin: "0px 0px 0px 10px" }, children: translate("login.password") }), _jsxs(Box, { sx: { position: "relative" }, children: [_jsx(TextInput, { variant: "outlined", source: "password", label: false, onChange: function (e) {
                                                setSignInInputs(__assign(__assign({}, signInInputs), { password: e.target.value }));
                                            }, disabled: loading, validate: required(), fullWidth: true, type: showPassword ? "text" : "password", placeholder: translate("login.YourPassword"), sx: {
                                                "& .MuiInputBase-root": {
                                                    borderRadius: "10px",
                                                    height: "3rem",
                                                    border: "1px solid #ccc",
                                                },
                                            } }), _jsx(IconButton, { onClick: function () { return setShowPassword(!showPassword); }, sx: { position: "absolute", right: 10, top: "40%", transform: "translateY(-50%)" }, children: showPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) })] })] })] }), _jsx(CardActions, { sx: { padding: "0 1em 1em 1em" }, children: _jsxs(Button, { className: "normal-text login-button", variant: "contained", type: "submit", color: "primary", disabled: loading, fullWidth: true, children: [console.log(loading, "==loading"), loading && _jsx(CircularProgress, { size: 25, thickness: 2 }), translate("login.signIn")] }) }), _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", padding: 2, children: [_jsx(Typography, { className: "small-text large-bold", textAlign: "center", color: "#395d7d", style: { cursor: "pointer" }, onClick: function () {
                                setCurComp("register");
                            }, sx: { margin: "0px 0px 50px 25px" }, padding: 0.85, children: translate("login.SignUpNow") }), _jsx(Typography, { className: "small-text large-bold", textAlign: "center", color: "#4d6a95", style: {
                                cursor: "pointer",
                                //paddingLeft: "0.01%"
                            }, padding: 0.85, onClick: function () {
                                setCurComp("forgetPW");
                            }, sx: { margin: "0px 25px 50px 0px" }, children: translate("login.ForgetYourPassword") })] })] }) }));
};
export default SignIn;

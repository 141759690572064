var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNotify, useSetLocale, } from 'react-admin';
import { Menu, MenuItem, Button, IconButton, Typography } from "@mui/material";
import GTranslateIcon from '@mui/icons-material/GTranslate';
export var CustomLocalesMenu = function () {
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var _b = useState("en"), lan = _b[0], setLan = _b[1];
    var notify = useNotify();
    var setLocale = useSetLocale();
    useEffect(function () {
        var _a;
        var cachedAuth = (_a = JSON.parse(localStorage.getItem("auth"))) !== null && _a !== void 0 ? _a : {};
        if (cachedAuth) {
            setLan(cachedAuth.language);
        }
    }, []);
    var handleLocaleChange = function (locale) {
        var _a;
        console.log(locale, '==locale change language');
        setLocale(locale);
        // onLocaleChange(locale);
        // notify(`Language changed to ${locale === 'zh-HK' ? '中文' : 'English'}`);
        setAnchorEl(null); // Close the menu
        setLan(locale);
        //reset auth
        var cachedAuth = (_a = JSON.parse(localStorage.getItem("auth"))) !== null && _a !== void 0 ? _a : {};
        if (cachedAuth) {
            var newCachedAuth = __assign({}, cachedAuth);
            newCachedAuth['language'] = locale;
            localStorage.setItem("auth", JSON.stringify(newCachedAuth));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Button, { style: { color: "white", backgroundColor: "" }, onClick: function (e) { return setAnchorEl(e.currentTarget); }, children: [_jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(GTranslateIcon, {}) }), _jsxs(Typography, { children: [" ", lan === 'zh-HK' ? '中文' : 'English'] })] }), _jsxs(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: function () { return setAnchorEl(null); }, children: [_jsx(MenuItem, { onClick: function () { return handleLocaleChange('en'); }, children: "English" }), _jsx(MenuItem, { onClick: function () { return handleLocaleChange('zh-HK'); }, children: "\u4E2D\u6587" })] })] }));
};

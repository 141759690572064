var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Typography, Box } from '@mui/material';
import { keyframes } from '@mui/system';
var circularMotion = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% { transform: rotate(0deg) translateX(50px) rotate(0deg); }\n  100% { transform: rotate(360deg) translateX(50px) rotate(-360deg); }\n"], ["\n  0% { transform: rotate(0deg) translateX(50px) rotate(0deg); }\n  100% { transform: rotate(360deg) translateX(50px) rotate(-360deg); }\n"])));
var wave = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0%, 40%, 100% { transform: translateY(0); }\n  20% { transform: translateY(-10px); }\n"], ["\n  0%, 40%, 100% { transform: translateY(0); }\n  20% { transform: translateY(-10px); }\n"])));
// Main blue color
var mainBlue = '#2f60b2';
export var LoadingAnimation = function () { return (_jsxs(Grid, { container: true, direction: "column", justifyContent: "center", alignItems: "center", spacing: 3, children: [_jsx(Grid, { item: true, children: _jsxs(Box, { sx: {
                    position: 'relative',
                    width: 120,
                    height: 120,
                }, children: [_jsx(Box, { sx: {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: 100,
                            height: 100,
                            border: "2px solid ".concat(mainBlue, "40"),
                            borderRadius: '50%',
                            transform: 'translate(-50%, -50%)',
                        } }), _jsx(Box, { sx: {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: 20,
                            height: 20,
                            backgroundColor: mainBlue,
                            borderRadius: '50%',
                            transform: 'translate(-50%, -50%)',
                            animation: "".concat(circularMotion, " 2s linear infinite"),
                        } })] }) }), _jsx(Grid, { item: true, children: _jsx(Typography, { variant: "h5", sx: {
                    fontWeight: 'bold',
                    letterSpacing: 5,
                    color: mainBlue,
                    textShadow: "0 0 10px ".concat(mainBlue, "40"),
                }, children: 'LOADING'.split('').map(function (letter, index) { return (_jsx("span", { style: {
                        display: 'inline-block',
                        animation: "".concat(wave, " 1.5s ease-in-out ").concat(index * 0.1, "s infinite"),
                    }, children: letter }, index)); }) }) })] })); };
export default LoadingAnimation;
var templateObject_1, templateObject_2;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
function Draggable(props) {
    var _a = useDraggable({
        id: props.id,
    }), attributes = _a.attributes, listeners = _a.listeners, setNodeRef = _a.setNodeRef, transform = _a.transform;
    var style = {
        // Outputs `translate3d(x, y, 0)`
        // height: "100px",
        border: "none",
        backgroundColor: "inherit",
        color: "#1561a5",
        transform: CSS.Translate.toString(transform),
    };
    return (_jsx("button", __assign({ ref: setNodeRef, style: style }, listeners, attributes, { children: props.children })));
}
export { Draggable };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDataProvider, useNotify, useRefresh, useTranslate } from 'react-admin';
import { Typography, Button, CircularProgress, Paper, Box, Container, Fade, LinearProgress } from '@mui/material';
import { CheckCircle, Error, ArrowForward, Dashboard as DashboardIcon } from '@mui/icons-material';
var styles = {
    root: {
        minHeight: '100vh',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 15rem)'
    },
    paper: {
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        backdropFilter: 'blur(10px)',
        borderRadius: '24px',
        overflow: 'hidden',
        position: 'relative'
    },
    headerSection: {
        background: 'linear-gradient(45deg, #1976d2 30%, #1ca2e3 90%)',
        padding: '2rem',
        textAlign: 'center',
        color: 'white'
    },
    contentSection: {
        padding: '2rem 1rem',
        textAlign: 'center',
        position: 'relative'
    },
    statusIcon: {
        fontSize: '5rem',
        animation: 'bounce 1s infinite'
    },
    title: {
        fontWeight: 700,
        marginBottom: '1rem',
        fontSize: {
            xs: '1.5rem',
            sm: '2rem',
            md: '2.5rem'
        }
    },
    subtitle: {
        opacity: 0.9,
        marginBottom: '2rem',
        fontSize: {
            xs: '1rem',
            sm: '1.1rem',
            md: '1.2rem'
        }
    },
    button: {
        padding: '1rem 2rem',
        borderRadius: '50px',
        fontSize: '1.1rem',
        fontWeight: 600,
        textTransform: 'none',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-3px)',
            boxShadow: '0 8px 15px rgba(0,0,0,0.2)'
        }
    },
    loadingBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '4px'
    },
    '@keyframes bounce': {
        '0%, 100%': {
            transform: 'translateY(0)'
        },
        '50%': {
            transform: 'translateY(-10px)'
        }
    }
};
var StatusIcon = function (_a) {
    var isLoading = _a.isLoading, paymentSuccess = _a.paymentSuccess;
    if (isLoading)
        return (_jsx(Box, { sx: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5rem' }, children: _jsx(CircularProgress, { size: 64, thickness: 4 }) }));
    return (_jsx(Fade, { in: true, timeout: 1000, children: _jsx(Box, { sx: { display: 'flex', justifyContent: 'center' }, children: paymentSuccess ? (_jsx(CheckCircle, { sx: __assign(__assign({}, styles.statusIcon), { color: 'success.main' }) })) : (_jsx(Error, { sx: __assign(__assign({}, styles.statusIcon), { color: 'error.main' }) })) }) }));
};
var PostPayment = function () {
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var _a = useState({
        isLoading: true,
        paymentSuccess: false
    }), state = _a[0], setState = _a[1];
    var _b = useState({}), transactionInfo = _b[0], setTransactionInfo = _b[1];
    useEffect(function () {
        var handlePayment = function () { return __awaiter(void 0, void 0, void 0, function () {
            var urlParams, sessionId, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        urlParams = new URLSearchParams(window.location.search);
                        sessionId = urlParams.get('sessionId');
                        if (!sessionId) {
                            throw new Error('No session ID provided');
                        }
                        return [4 /*yield*/, dataProvider.createOne("payment/buyQuota/success/".concat(sessionId), {})];
                    case 1:
                        data = _a.sent();
                        setTransactionInfo(data.data);
                        notify(translate('pages.postPayment.paymentSuccess'), { type: 'success' });
                        setState({ isLoading: false, paymentSuccess: true });
                        refresh();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Payment processing error:', error_1);
                        notify(translate('pages.postPayment.paymentFail'), { type: 'error' });
                        setState({ isLoading: false, paymentSuccess: false });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        handlePayment();
    }, []);
    return (_jsx(Box, { sx: styles.root, children: _jsx(Container, { maxWidth: "lg", sx: styles.container, children: _jsx(Fade, { in: true, timeout: 800, children: _jsxs(Paper, { elevation: 24, sx: styles.paper, children: [state.isLoading && (_jsx(LinearProgress, { sx: styles.loadingBar })), _jsxs(Box, { sx: styles.headerSection, children: [_jsx(Typography, { variant: "h3", sx: styles.title, children: state.isLoading ?
                                        translate('pages.postPayment.processingPayment') :
                                        translate('pages.postPayment.paymentStatus') }), state.isLoading ? (_jsx(Typography, { variant: "h6", sx: __assign(__assign({}, styles.subtitle), { color: 'rgba(255, 255, 255, 0.9)' }), children: translate('pages.postPayment.pleaseWait') })) : (state.paymentSuccess ? (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h6", sx: __assign(__assign({}, styles.subtitle), { color: '#ffffff', fontWeight: 600, marginBottom: 1, fontSize: '1.1rem', textShadow: '0 0 10px rgba(255, 255, 255, 0.3)' }), children: "".concat(translate('pages.postPayment.successMessage'), " ").concat(transactionInfo.product, ".") }), _jsx(Typography, { variant: "h6", sx: __assign(__assign({}, styles.subtitle), { color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.95rem', fontWeight: 400 }), children: "".concat(translate('pages.postPayment.leftQuota'), " ").concat(transactionInfo.organization.quota) })] })) : (_jsx(Typography, { variant: "h6", sx: __assign(__assign({}, styles.subtitle), { color: '#FFB4B4', textShadow: '0 0 8px rgba(255, 180, 180, 0.3)' }), children: translate('pages.postPayment.failureMessage') })))] }), _jsxs(Box, { sx: styles.contentSection, children: [_jsx(StatusIcon, { isLoading: state.isLoading, paymentSuccess: state.paymentSuccess }), _jsx(Box, { mt: 4, children: _jsx(Button, { variant: "contained", disabled: state.isLoading, onClick: function () { return navigate("/admin/subjects"); }, color: state.paymentSuccess ? "primary" : "error", sx: styles.button, startIcon: _jsx(DashboardIcon, {}), endIcon: _jsx(ArrowForward, {}), children: translate("pages.postPayment.backToSubjects") }) })] })] }) }) }) }));
};
export default PostPayment;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import { Button, Card, CardActions, CircularProgress, Grid, Typography } from '@mui/material';
import { required, TextInput, useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
// import LoginLogo from '../assets/logos/login logo.png'
import ICSLogo from '../../assets/logos/ics logo.png';
import "../../styles/global.module.css";
import OTPInput from '../helper/Breadcrumb/OTPInput';
var LoginAuth = function (_a) {
    var loading = _a.loading, signInInputs = _a.signInInputs, setSignInInputs = _a.setSignInInputs, setCurComp = _a.setCurComp, serverOTP = _a.serverOTP, setServerOTP = _a.setServerOTP, handleTwofaLogin = _a.handleTwofaLogin, enableTwofaResend = _a.enableTwofaResend, setEnableTwofaResend = _a.setEnableTwofaResend, isMobileScreen = _a.isMobileScreen, isTabScreen = _a.isTabScreen;
    var translate = useTranslate();
    // const [countdown, setCountdown] = useState(60);
    var _b = useState(60), timer = _b[0], setTimer = _b[1];
    var increment = useRef(null);
    var cardWidth = "40%";
    switch (true) {
        case isMobileScreen:
            cardWidth = "75%";
            break;
        case isTabScreen:
            cardWidth = "60%";
            break;
    }
    var resendCountDown = function () {
        increment.current = setInterval(function () {
            setTimer(function (timer) {
                if (timer > 0)
                    return timer - 1;
                else {
                    // clearInterval(increment.current);
                    setEnableTwofaResend(false);
                    setTimer(60);
                    clearInterval(increment.current);
                    return timer;
                }
            });
        }, 1000);
    };
    var handleStartCountDown = function () {
        setEnableTwofaResend(true);
        resendCountDown();
        setTimer(60); //[back] here is the timer for resend
    };
    var _c = useState(''), otp = _c[0], setOtp = _c[1];
    return (_jsx(Box, { sx: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "flex-start",
            background: "#ebeff2",
            backgroundSize: "cover",
        }, children: _jsxs(Card, { sx: {
                //minWidth: "60%",
                // maxWidth: 550,
                marginTop: "3em",
                borderRadius: "40px",
                width: cardWidth,
                maxWidth: "27rem",
                boxShadow: "20px 20px 25px rgba(77, 106, 149, 0.5)", // Light blue color
            }, children: [_jsx(Box, { sx: {
                        margin: "1em",
                        display: "flex",
                        justifyContent: "center",
                    }, children: _jsx("img", { src: ICSLogo, alt: "logo", height: 135, style: {
                            transition: "transform 0.8s ease-in-out",
                        }, onMouseEnter: function (e) {
                            e.currentTarget.style.transform = "scale(2)";
                        }, onMouseLeave: function (e) {
                            e.currentTarget.style.transform = "scale(1)";
                        } }) }), _jsx(Box, { sx: {
                        marginTop: "0em",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        color: function (theme) { return theme.palette.grey[500]; },
                    }, children: _jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", style: { backgroundColor: "", }, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { className: " large-bold", textAlign: "center", color: "black", children: translate("login.AI_Screening_Platform") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { className: " normal-bold", textAlign: "center", color: "black", children: translate("login.signIn") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { className: "no-bold", textAlign: "center", color: "black", sx: { marginTop: "1.5em", paddingX: "1em" }, children: translate("login.forgetPW.caption.willSend2fa") }) })] }) }), _jsxs(Box, { sx: { padding: '0 1em 1em 1em' }, children: [_jsxs(Box, { sx: { marginTop: '0em' }, children: [_jsx(Typography, { className: "small-text large-bold", color: "#4d6a95", sx: { margin: "0px 0px 0px 10px" }, children: translate("login.username") }), _jsx(TextInput, { autoFocus: true, variant: "outlined", source: "", label: false, disabled: true, validate: required(), fullWidth: true, defaultValue: signInInputs === null || signInInputs === void 0 ? void 0 : signInInputs.username, placeholder: translate("login.YourUsername"), sx: {
                                        "& .MuiInputBase-root": {
                                            borderRadius: "10px",
                                            height: "3rem",
                                            border: "1px solid #ccc",
                                        },
                                    } }), _jsx(Typography, { className: "small-text large-bold", color: "#4d6a95", sx: { margin: "0px 0px 0px 10px" }, children: translate("login.authenCode") }), _jsx(OTPInput, { setOtp: setServerOTP, otp: serverOTP })] }), _jsxs(Grid, { item: true, xs: 12, display: "flex", children: [_jsx(Typography, { className: "small-text large-bold", textAlign: "center", color: "Black", sx: { margin: "0px 0px 0px 5px" }, children: translate("login.didntReceive") }), !enableTwofaResend && (_jsx(Typography, { className: "small-text large-bold", textAlign: "center", color: "#4d6a95", style: { cursor: "pointer" }, onClick: function () {
                                        handleTwofaLogin(true);
                                        handleStartCountDown();
                                    }, sx: { marginLeft: "10px" }, children: translate("login.resend") })), enableTwofaResend && (_jsx(Typography, { className: "small-text large-bold", textAlign: "center", color: "#4d6a95", sx: { marginLeft: "10px" }, children: "Resend in ".concat(timer, "s") }))] })] }), _jsx(CardActions, { sx: { padding: '0 1em 1em 1em' }, children: _jsxs(Button, { className: "login-button", variant: "contained", type: "submit", color: "primary", disabled: loading, fullWidth: true, onClick: function () {
                            handleTwofaLogin();
                        }, children: [loading && (_jsx(CircularProgress, { size: 25, thickness: 2 })), translate('ra.auth.sign_in')] }) }), _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", padding: 2, children: _jsx(Typography, { className: " large-bold", textAlign: "center", color: "#4d6a95", style: { cursor: "pointer" }, fontSize: "0.85em", sx: { margin: "0px 0px 50px 25px" }, onClick: function () {
                            setServerOTP("");
                            setSignInInputs({ username: "", password: "", email: "" });
                            setCurComp("signIn");
                        }, children: translate("login.BackToSignIn") }) })] }) }));
};
export default LoginAuth;

var EducationLevel;
(function (EducationLevel) {
    EducationLevel["Year_0_3"] = "0-3 yrs";
    EducationLevel["Year_4_6"] = "4-6 yrs";
    EducationLevel["Year_7_9"] = "7-9 yrs";
    EducationLevel["Year_10_12"] = "10-12 yrs";
    EducationLevel["Year"] = "> 12 yrs";
    EducationLevel["Unknown"] = "Unknown";
})(EducationLevel || (EducationLevel = {}));
var EducationLevelInArray = Object.values(EducationLevel);
export { EducationLevel, EducationLevelInArray };

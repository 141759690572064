//  MUI
var EyeMuiChoices = [
    { value: 'Left', label: 'Left' },
    { value: 'Right', label: 'Right' },
    { value: 'Both', label: 'Both' }
];
var AIModelMuiChoices = [
    { value: 'FP + OCT', label: 'FP + OCT' },
    { value: 'FP', label: 'FP' },
    { value: 'OCT', label: 'OCT' }
];
var RiskOfAdMuiChoices = [
    { value: 'High', label: 'High' },
    { value: 'Low', label: 'Low' },
];
var ExamStatusMuiChoices = [
    { value: 'Pending', label: 'Pending' },
    { value: 'In progress', label: 'In progress' },
    { value: 'Completed', label: 'Completed' },
];
var StatusMuiChoices = [
    { value: 'Active', label: 'Active' },
    { value: 'Disabled', label: 'Disabled' },
    { value: 'Not activate', label: 'Not activate' },
];
export { EyeMuiChoices, AIModelMuiChoices, RiskOfAdMuiChoices, StatusMuiChoices, ExamStatusMuiChoices, };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { flattenObjectWithUnderscores, excludeFromObj, } from "../utils/helper";
import { useDataProvider, useNotify, useRefresh, useTranslate, } from "react-admin";
import { Grid, } from "@mui/material";
import ExportList from "./ExportList";
import RightPanel from "./RightPanel";
import ExportDialog from "./ExportDialog";
import { dataImportstyles as styles } from "./helper/styles";
import { dummyDataExport, dummyPdfData } from "./helper/dummyData";
import ExportFunc from "./helper/ExportFunc";
import { acl } from "../utils/dummyData";
import { BODY_GRID_PADDING, } from "../styles/styleConstants.data";
var DataExport = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var resource = "subjects";
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var _a = useState([]), data = _a[0], setData = _a[1]; //data table data
    // const resource = "dataexport";
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(acl), acessControlList = _c[0], setAcessControlList = _c[1];
    var _d = useState({
    // analyzeFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // analyzeToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    }), filters = _d[0], setFilters = _d[1];
    // dialog for preview export
    var _e = useState(false), dialogOpened = _e[0], setDialogOpened = _e[1];
    var _f = useState({}), dialogData = _f[0], setDialogData = _f[1];
    // data table header
    var _g = useState([
        {
            // id: translate(`pages.subject.fields.id`),
            customId: translate("pages.subject.fields.customId"),
            // organization: translate(`pages.userList.fields.organization`),
            firstname: translate("pages.subject.fields.firstname"),
            lastname: translate("pages.subject.fields.lastname"),
            gender: translate("pages.subject.fields.gender"),
            educationLevel: translate("pages.subject.fields.educationLevel"),
            dateOfBirth: translate("pages.subject.fields.dateOfBirth"),
            probScore: translate("pages.exam.fields.confidence_level_avg"),
            isHeader: true,
            selected: false,
        },
    ]), dataGridHeader = _g[0], setDataGridHeader = _g[1];
    var _h = useState(false), selectedAll = _h[0], setSelectedAll = _h[1]; //select all for data export
    // right panel field
    var _j = useState({
        subjectInformation: false,
        images: {
            fp: false,
            oct: false,
        },
        retinADReports: {
            fp: false,
            oct: false,
            fpAndoct: false,
        },
        retinADProbabilityScores: {
            fp: false,
            oct: false,
            fpAndoct: false,
        },
        questionnaireResults: false,
        // criteria
        examDate: false,
        analyzeDate: false,
    }), exportFields = _j[0], setExportFields = _j[1];
    //  right panel field - criteria - selected date
    var _k = useState({
    // analyzeFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // analyzeToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    }), exportDateInfoFields = _k[0], setExportDateInfoFields = _k[1];
    // data that will pass to export
    var _l = useState(dummyDataExport), dataToExport = _l[0], setDataToExport = _l[1]; //data table data
    //pdf blob for dataToExport
    // const [pdfArrExist, setPdfArrExist] = useState(true); // to know if there will be pdf
    var _m = useState(dummyPdfData), pdfArr = _m[0], setPdfArr = _m[1]; //all fp pdf return from BE
    var _o = useState({}), pdfBlobs = _o[0], setPdfBlobs = _o[1];
    //for export dialog - first click - preview information
    var previewExport = function (isGroupingSubjects) {
        var dataExport;
        var updatedDialogData;
        // only export selected subjects as a group
        if (isGroupingSubjects) {
            updatedDialogData = {
                selected: data.filter(function (item) { return item.selected === true; }).length,
                dataExport: {
                    subjectInformation: true,
                    groupSubjectsOnly: true,
                },
                criteria: {},
            };
        }
        else {
            updatedDialogData = {
                selected: data.filter(function (item) { return item.selected === true; }).length,
                dataExport: exportFields,
                criteria: exportDateInfoFields,
            };
        }
        setDialogData(updatedDialogData);
        setDialogOpened(true);
    };
    var checkIfExportFieldHasSelections = function (obj) {
        return Object.keys(obj).some(function (key) {
            // ignore date selection
            if (key === "examDate" || key === "analyzeDate") {
                return false; // Ignoring examDate and analyzeDate
            }
            if (typeof obj[key] === "object") {
                return checkIfExportFieldHasSelections(obj[key]);
            }
            return obj[key] === true;
        });
    };
    //for export confirm
    var reloadRecord = function () { return __awaiter(void 0, void 0, void 0, function () {
        var selectedExportFields, exportFieldsFilter, subjectIdArr, params, newDataToExport, objIndex;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            selectedExportFields = checkIfExportFieldHasSelections(exportFields);
            if (!selectedExportFields && !dialogData.dataExport.groupSubjectsOnly) {
                notify(translate("pages.dataExport.notify.select"));
                return [2 /*return*/];
            }
            setIsLoading(true);
            exportFieldsFilter = flattenObjectWithUnderscores(__assign({}, exportFields));
            exportFieldsFilter = excludeFromObj(exportFieldsFilter, [
                "examDate",
                "analyzeDate",
            ]);
            //check for the date
            if (exportFields.examDate && exportDateInfoFields.examFromDate) {
                exportFieldsFilter.examFromDate =
                    (_a = exportDateInfoFields === null || exportDateInfoFields === void 0 ? void 0 : exportDateInfoFields.examFromDate) !== null && _a !== void 0 ? _a : "";
            }
            if (exportFields.examDate && exportDateInfoFields.examToDate) {
                exportFieldsFilter.examToDate = (_b = exportDateInfoFields === null || exportDateInfoFields === void 0 ? void 0 : exportDateInfoFields.examToDate) !== null && _b !== void 0 ? _b : "";
            }
            if (exportFields.analyzeDate && exportDateInfoFields.analyzeFromDate) {
                exportFieldsFilter.analyzeFromDate =
                    (_c = exportDateInfoFields === null || exportDateInfoFields === void 0 ? void 0 : exportDateInfoFields.analyzeFromDate) !== null && _c !== void 0 ? _c : "";
            }
            if (exportFields.analyzeDate && exportDateInfoFields.analyzeToDate) {
                exportFieldsFilter.analyzeToDate =
                    (_d = exportDateInfoFields === null || exportDateInfoFields === void 0 ? void 0 : exportDateInfoFields.analyzeToDate) !== null && _d !== void 0 ? _d : "";
            }
            subjectIdArr = (_e = data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.selected === true; })) === null || _e === void 0 ? void 0 : _e.map(function (el) { return String(el.id); });
            params = {
                data: {
                    subject_id: subjectIdArr,
                    // subject_id: ["78335967-e8b4-46c9-bb49-f6d93376e4e4"],
                    filters: exportFieldsFilter,
                },
            };
            if (dialogData.dataExport.groupSubjectsOnly) {
                params.data.filters = { subjectInformation: true };
            }
            newDataToExport = [];
            objIndex = 0;
            dataProvider
                .dataExport(resource, params)
                .then(function (rtnData) { return __awaiter(void 0, void 0, void 0, function () {
                var _i, newDataToExport_1, obj;
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                return __generator(this, function (_o) {
                    switch (_o.label) {
                        case 0:
                            newDataToExport = (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data) === null || _a === void 0 ? void 0 : _a.data; //[back] [1107] resume this
                            // newDataToExport = dummyDataExport[0]['data']; //[back] [1107] remove this
                            console.log(newDataToExport, "==log rtnData", dialogData);
                            if (!dialogData.dataExport.groupSubjectsOnly) return [3 /*break*/, 2];
                            return [4 /*yield*/, ExportFunc({
                                    data: newDataToExport,
                                    onlyGroupSubjectData: true,
                                    dataGridHeader: dataGridHeader,
                                    fileName: translate("pages.dataExport.fields.group_subjects"),
                                })];
                        case 1:
                            _o.sent();
                            return [3 /*break*/, 7];
                        case 2:
                            _i = 0, newDataToExport_1 = newDataToExport;
                            _o.label = 3;
                        case 3:
                            if (!(_i < newDataToExport_1.length)) return [3 /*break*/, 6];
                            obj = newDataToExport_1[_i];
                            console.log(obj, "==obj new");
                            return [4 /*yield*/, ExportFunc({
                                    data: obj,
                                    dataGridHeader: dataGridHeader,
                                    subjectId: subjectIdArr[objIndex],
                                    fileName: "".concat((_c = (_b = obj === null || obj === void 0 ? void 0 : obj.subject) === null || _b === void 0 ? void 0 : _b.lastname) !== null && _c !== void 0 ? _c : "").concat((_e = (_d = obj === null || obj === void 0 ? void 0 : obj.subject) === null || _d === void 0 ? void 0 : _d.firstname) !== null && _e !== void 0 ? _e : "", "-").concat((_h = (_g = (_f = obj === null || obj === void 0 ? void 0 : obj.subject) === null || _f === void 0 ? void 0 : _f.organization) === null || _g === void 0 ? void 0 : _g.orgName) !== null && _h !== void 0 ? _h : "NA", "-").concat((_k = (_j = obj === null || obj === void 0 ? void 0 : obj.subject) === null || _j === void 0 ? void 0 : _j.customId) !== null && _k !== void 0 ? _k : "", "-").concat((_m = (_l = obj === null || obj === void 0 ? void 0 : obj.subject) === null || _l === void 0 ? void 0 : _l.id) !== null && _m !== void 0 ? _m : ""),
                                    retinADReports: obj.retinADReports,
                                })];
                        case 4:
                            _o.sent();
                            objIndex += 1;
                            _o.label = 5;
                        case 5:
                            _i++;
                            return [3 /*break*/, 3];
                        case 6:
                            setDataToExport(newDataToExport);
                            setIsLoading(false);
                            _o.label = 7;
                        case 7:
                            notify(translate("pages.dataExport.notify.exported"));
                            closeDialog();
                            return [2 /*return*/];
                    }
                });
            }); })
                .catch(function (error) {
                // Handle any errors that occurred
                console.error(error, "==err");
                setIsLoading(false);
                notify(translate('error.dataExport.exportFail'), {
                    type: 'error',
                });
            });
            // csv download
            console.log(params, "==params");
            return [2 /*return*/];
        });
    }); };
    var closeDialog = function () {
        setDialogOpened(false);
        setIsLoading(false);
    };
    //check access control list
    useEffect(function () {
        var _a;
        var newExportFields = __assign({}, exportFields);
        if (!acessControlList.data_export_normal.export_retinad_probability_scores) {
            delete newExportFields.retinADProbabilityScores;
        }
        setExportFields(newExportFields);
        // get ACL
        var cachedAuth = (_a = JSON.parse(localStorage.getItem("auth"))) !== null && _a !== void 0 ? _a : {};
        var settingsParams = {};
        if (cachedAuth) {
            settingsParams.id = cachedAuth.id;
            dataProvider
                .getOneWithoutDataJSON("users", settingsParams)
                .then(function (rtnData) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                var newACList = ((_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.ACL) || {};
                setAcessControlList(newACList);
                localStorage.setItem("appBarOrg", (_e = (_d = (_c = (_b = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c['current_organization']) === null || _d === void 0 ? void 0 : _d[1]) !== null && _e !== void 0 ? _e : "N/A");
                localStorage.setItem("userOrgId", (_j = (_h = (_g = (_f = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _f === void 0 ? void 0 : _f.info) === null || _g === void 0 ? void 0 : _g['current_organization']) === null || _h === void 0 ? void 0 : _h[0]) !== null && _j !== void 0 ? _j : "N/A");
            })
                .catch(function (error) {
                // Handle any errors that occurred
                console.error(error);
                localStorage.setItem("appBarOrg", "N/A");
                localStorage.setItem("userOrgId", "N/A");
                notify(translate('error.ACL.getACLFailed'), {
                    type: 'error',
                });
            });
        }
    }, []);
    return (_jsxs(Grid, { container: true, style: styles.dataExport, padding: BODY_GRID_PADDING, children: [_jsx(Grid, { item: true, xs: 12, lg: 8, children: _jsx(ExportList, { props: resource, acessControlList: acessControlList, exportFields: exportFields, data: data, setData: setData, filters: filters, setFilters: setFilters, isLoading: isLoading, setIsLoading: setIsLoading, previewExport: previewExport, dataGridHeader: dataGridHeader, setDataGridHeader: setDataGridHeader, selectedAll: selectedAll, setSelectedAll: setSelectedAll }) }), _jsx(Grid, { item: true, xs: 12, lg: 4, marginTop: 1, sx: {
                    position: 'sticky',
                    top: 50,
                    height: 'fit-content'
                }, children: _jsx(RightPanel, { acessControlList: acessControlList, translate: translate, exportFields: exportFields, setExportFields: setExportFields, exportDateInfoFields: exportDateInfoFields, setExportDateInfoFields: setExportDateInfoFields }) }), dialogOpened && (_jsx(ExportDialog, { opened: dialogOpened, data: dialogData, 
                // openDialog={openDialog}
                closeDialog: closeDialog, reloadRecord: reloadRecord, translate: translate }))] }));
};
export default DataExport;

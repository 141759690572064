import { fetchUtils } from "react-admin";
import SenRestProvider from "./senRestProvider";
// const headerOptions = {
//     headers: new Headers({
//         'Authorization': `Bearer ${cachedToken}`
//     }),
// };
var fetchJson = function (url, options) {
    if (options === void 0) { options = {}; }
    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/json',
        });
    }
    var cachedToken = localStorage.getItem('accessToken');
    if (cachedToken) {
        options.headers.set('Authorization', "Bearer ".concat(cachedToken));
    }
    return fetchUtils.fetchJson(url, options);
};
export var dataProvider = SenRestProvider(import.meta.env.VITE_API_BASE_URL, "Content-Range", fetchJson);
